import { FC, useEffect } from 'react';
import { NeuCol, NeuContainer, NeuRow, NeuSpinner } from '@neutron/react';
import { useNavigate } from 'react-router-dom';

const Loading: FC<{ centered?: boolean; extendedTimeOut?: boolean }> = ({
  centered = true,
  extendedTimeOut = false
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    const loadingTimeout = setTimeout(
      () => {
        // Loading timeout will redirect to offline page
        navigate('/404');
      },
      extendedTimeOut ? 35000 : 20000
    );
    return () => clearInterval(loadingTimeout);
  }, []);

  return (
    <NeuContainer className="center-content__col--top-spacing neu-text-center h-100">
      <NeuRow className="h-100" style={{ marginTop: '-2rem' }}>
        <NeuCol
          className={`d-flex flex-column${
            centered ? ' justify-content-center' : ''
          }`}
          cols={12}
        >
          <span className="sr-only" role="status">
            Loading...
          </span>
          <NeuSpinner
            className="mt-n2"
            ariaLabel="Loading Spinner"
            role="status"
          />
        </NeuCol>
      </NeuRow>
    </NeuContainer>
  );
};

export default Loading;
