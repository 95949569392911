import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import ACTIONS from './actions/actionType';
import { getAuthorizedUser, getAuthToken, getUser34 } from './saga/Auth.saga';
import {
  addFavorite,
  removeFavorite,
  updateCurrentFacility
} from './saga/Account.saga';
import {
  getUsers,
  getEncounters,
  getUsersToExport,
  getApiVersion
} from './saga/Admin.saga';
import {
  getFacilityUnits,
  changeUnitLocTypeAndFlag,
  getAllUnits,
  getSassToken
} from './saga/Config.saga';
import {
  findEmployees,
  searchEmployees,
  getEmployeesToRound,
  getCSRNSubjectsToRoundForList,
  getCNEdSubjectsToRoundForList,
  getEmployeesConfig,
  getMyEmployees,
  getDelegateSettings,
  getEmployeeAccountDetails,
  postEmployeeNote,
  removeEmployeeNote,
  searchEmployeesToShare,
  getCSRNEmployeesConfig,
  updateCSRNHiddenStatus,
  getCNEdEmployeesConfig,
  updateCNEdHiddenStatus
} from './saga/Employee.saga';
import {
  postPatientNote,
  removePatientNote,
  getPatientByAccountNumber
} from './saga/Patient.saga';
import {
  getPatientRoundHistory,
  searchPatientHistory,
  getEmployeeRoundHistory,
  getEmployeeRoundsReport,
  getEmployeeFreqReport,
  getPatientRoundsReport,
  getComplianceReport,
  getCensusReport,
  getPatientRounds,
  getEmployeeRounds,
  getEmployeeFreq
} from './saga/Report.saga';
import { postRound, getRound } from './saga/Round.saga';
import {
  getStoplightsByLocation,
  getAllStoplightsByLocation,
  postNewStoplight,
  deleteStoplight,
  getStoplightsById,
  searchStoplight,
  updateStoplight,
  postCommentToStoplight
} from './saga/Stoplight.saga';
import {
  getCategoriesByType,
  getTasksByAccountNumber,
  postNewTask,
  getEmployeeSharedWith,
  completeTask,
  deleteTask,
  getTasksByEmployeeId,
  searchIssues,
  getDetailsByTicketNumber,
  getSCLocations,
  verifyControlNumber,
  getFeedbackByUnitIds,
  getFeedbackByEmployeeList,
  getFacilityIssues,
  getAllFacilityIssues,
  getSingleIssueOrFeedbackFromEmail,
  getTaskDiscussion,
  postCommentToTask,
  getIssueById,
  getIssueListByFacilityId,
  getAllIssuesListByFacilityId,
  getFeedbackListByFacilityId,
  getAllFacilityFeedback,
  getAllFeedbackListByFacilityId,
  shareTask,
  getGELocations
} from './saga/Task.saga';
import {
  getTemplateMaster,
  getRoundSurvey,
  getAllQuestions,
  addNewQuestion,
  getTemplatesTree,
  getTemplateById,
  editQuestion,
  postMasterTemplate,
  getTemplateDoc,
  getActivePatientTemplates
} from './saga/Template.saga';
import {
  getPatientsToRound,
  getRounds,
  getUserRoles,
  getFacilities,
  getDivisions,
  postUserAccess,
  getDischarge,
  addEmployeesToUser,
  removeEmployeeFromUser,
  postUserAccessRevoke,
  postNewPic,
  getPatientsToRoundForCensus,
  addDelegateToUser,
  removeDelegateFromUser,
  postEmployeeListConfig,
  getEmployeeNotes,
  removeUserAsDelegate
} from './saga/User.saga';

// eslint-disable-next-line func-names
export default function* () {
  return yield all([
    // Auth watches
    takeEvery(ACTIONS.AUTH.GET_USER, getAuthorizedUser),
    takeEvery(ACTIONS.AUTH.GET_AUTH_TOKEN, getAuthToken),
    takeEvery(ACTIONS.AUTH.GET_USER34, getUser34),

    // Account watches
    takeEvery(ACTIONS.ACCOUNT.POST_ADD_PRIMARY, addFavorite),
    takeEvery(ACTIONS.ACCOUNT.POST_REMOVE_PRIMARY, removeFavorite),
    takeEvery(ACTIONS.ACCOUNT.POST_CURRENT_FACILITY, updateCurrentFacility),

    // Admin watchers
    takeEvery(ACTIONS.ADMIN.GET_USERS, getUsers),
    takeEvery(ACTIONS.ADMIN.GET_ENCOUNTERS, getEncounters),
    takeEvery(ACTIONS.ADMIN.GET_USERS_TO_EXPORT, getUsersToExport),
    takeEvery(ACTIONS.ADMIN.GET_API_VERSION, getApiVersion),

    // Config Watchers
    takeEvery(ACTIONS.CONFIG.GET_SASS_TOKEN, getSassToken),
    takeEvery(
      ACTIONS.CONFIG.CHANGE_UNIT_LOC_TYPE_FLAG,
      changeUnitLocTypeAndFlag
    ),
    takeEvery(ACTIONS.CONFIG.GET_ALL_UNITS, getAllUnits),
    takeEvery(ACTIONS.CONFIG.GET_UNITS_BY_FACILITY, getFacilityUnits),

    // Employee watches
    takeEvery(ACTIONS.EMPLOYEE.GET_EMPLOYEES, findEmployees),
    takeEvery(ACTIONS.EMPLOYEE.SEARCH_EMPLOYEES, searchEmployees),
    takeEvery(
      ACTIONS.EMPLOYEE.SEARCH_EMPLOYEES_TO_SHARE,
      searchEmployeesToShare
    ),
    takeEvery(ACTIONS.EMPLOYEE.GET_EMPLOYEES_TO_ROUND, getEmployeesToRound),
    takeEvery(
      ACTIONS.EMPLOYEE.GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST,
      getCSRNSubjectsToRoundForList
    ),
    takeEvery(
      ACTIONS.EMPLOYEE.GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST,
      getCNEdSubjectsToRoundForList
    ),
    takeEvery(ACTIONS.EMPLOYEE.GET_EMPLOYEES_DELEGATED, getEmployeesConfig),
    takeLatest(
      ACTIONS.EMPLOYEE.GET_CSRN_EMPLOYEES_CONFIG,
      getCSRNEmployeesConfig
    ),
    takeEvery(
      ACTIONS.EMPLOYEE.UPDATE_CSRN_EMPLOYEES_HIDDEN_STATUS,
      updateCSRNHiddenStatus
    ),
    takeLatest(
      ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG,
      getCNEdEmployeesConfig
    ),
    takeEvery(
      ACTIONS.EMPLOYEE.UPDATE_CNED_EMPLOYEES_HIDDEN_STATUS,
      updateCNEdHiddenStatus
    ),
    takeEvery(ACTIONS.EMPLOYEE.GET_MY_EMPLOYEES, getMyEmployees),
    takeEvery(ACTIONS.EMPLOYEE.GET_DELEGATE_SETTINGS, getDelegateSettings),
    takeEvery(
      ACTIONS.EMPLOYEE.GET_EMPLOYEE_ACCOUNT_DETAILS,
      getEmployeeAccountDetails
    ),
    takeEvery(ACTIONS.EMPLOYEE.ADD_NOTE, postEmployeeNote),
    takeEvery(ACTIONS.EMPLOYEE.REMOVE_NOTE, removeEmployeeNote),

    // Feedback watches
    takeEvery(ACTIONS.TASK.GET_FEEDBACK_BY_UNIT_IDS, getFeedbackByUnitIds),
    takeEvery(
      ACTIONS.TASK.GET_FEEDBACK_BY_EMPLOYEE_LIST,
      getFeedbackByEmployeeList
    ),

    // Patient watches
    takeEvery(ACTIONS.PATIENT.ADD_NOTE, postPatientNote),
    takeEvery(ACTIONS.PATIENT.REMOVE_NOTE, removePatientNote),
    takeEvery(ACTIONS.PATIENT.PATIENT_DETAILS, getPatientByAccountNumber),

    // Report watches
    takeEvery(ACTIONS.REPORT.SEARCH_PATIENT_HISTORY, searchPatientHistory),
    takeEvery(ACTIONS.REPORT.GET_COMPLIANCE_REPORT, getComplianceReport),
    takeEvery(ACTIONS.REPORT.GET_PATIENT_ROUND_HISTORY, getPatientRoundHistory),
    takeEvery(
      ACTIONS.REPORT.GET_EMPLOYEE_ROUND_HISTORY,
      getEmployeeRoundHistory
    ),
    takeLatest(
      ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS_REPORT,
      getEmployeeRoundsReport
    ),
    takeLatest(ACTIONS.REPORT.GET_EMPLOYEE_FREQ_REPORT, getEmployeeFreqReport),
    takeLatest(
      ACTIONS.REPORT.GET_PATIENT_ROUNDS_REPORT,
      getPatientRoundsReport
    ),
    takeLatest(ACTIONS.REPORT.GET_PATIENT_ROUNDS, getPatientRounds),
    takeLatest(ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS, getEmployeeRounds),
    takeLatest(ACTIONS.REPORT.GET_EMPLOYEE_FREQ, getEmployeeFreq),
    takeEvery(ACTIONS.REPORT.GET_CENSUS_REPORT, getCensusReport),

    // Round actions
    takeEvery(ACTIONS.ROUND.COMPLETE_ROUND, postRound),
    takeEvery(ACTIONS.ROUND.GET_ROUND, getRound),

    // Stoplight watches
    takeEvery(
      ACTIONS.STOPLIGHT.GET_STOPLIGHTS_BY_LOCATION,
      getStoplightsByLocation
    ),
    takeEvery(
      ACTIONS.STOPLIGHT.GET_ALL_STOPLIGHTS_BY_LOCATION,
      getAllStoplightsByLocation
    ),
    takeEvery(ACTIONS.STOPLIGHT.GET_STOPLIGHT_BY_ID, getStoplightsById),
    takeEvery(ACTIONS.STOPLIGHT.POST_NEW_STOPLIGHT, postNewStoplight),
    takeEvery(ACTIONS.STOPLIGHT.DELETE_STOPLIGHT, deleteStoplight),
    takeEvery(ACTIONS.STOPLIGHT.UPDATE_STOPLIGHT, updateStoplight),
    takeEvery(ACTIONS.STOPLIGHT.SEARCH_STOPLIGHTS, searchStoplight),
    takeEvery(
      ACTIONS.STOPLIGHT.POST_COMMENT_TO_STOPLIGHT,
      postCommentToStoplight
    ),

    // Task watches

    takeEvery(ACTIONS.TASK.GET_ISSUES_BY_FACILITY, getFacilityIssues),
    takeEvery(ACTIONS.TASK.GET_ALL_ISSUES_BY_FACILITY, getAllFacilityIssues),
    takeEvery(ACTIONS.TASK.GET_EMP_SHARED_WITH, getEmployeeSharedWith),
    takeEvery(ACTIONS.TASK.COMPLETE_TASK, completeTask),
    takeEvery(ACTIONS.TASK.DELETE_TASK, deleteTask),
    takeEvery(ACTIONS.TASK.SHARE_TASK, shareTask),
    takeEvery(
      ACTIONS.TASK.GET_TASKS_BY_ACCOUNT_NUMBER,
      getTasksByAccountNumber
    ),
    takeEvery(ACTIONS.TASK.GET_TASKS_BY_EMPLOYEE_ID, getTasksByEmployeeId),
    takeEvery(ACTIONS.TASK.GET_CATEGORIES, getCategoriesByType),
    takeEvery(ACTIONS.TASK.POST_NEW_TASK, postNewTask),
    takeEvery(ACTIONS.TASK.SEARCH_ISSUES, searchIssues),
    takeEvery(ACTIONS.TASK.GET_TICKET_DETAILS, getDetailsByTicketNumber),
    takeEvery(ACTIONS.TASK.GET_SC_LOCATIONS, getSCLocations),
    takeEvery(ACTIONS.TASK.GET_GE_LOCATIONS, getGELocations),
    takeEvery(ACTIONS.TASK.VERIFY_CONTROL_NUMBER, verifyControlNumber),
    takeEvery(
      ACTIONS.TASK.GET_SINGLE_TASK_TO_COMPLETE,
      getSingleIssueOrFeedbackFromEmail
    ),
    takeEvery(ACTIONS.TASK.GET_TASK_DISCUSSION, getTaskDiscussion),
    takeEvery(ACTIONS.TASK.POST_TASK_COMMENT, postCommentToTask),
    takeEvery(ACTIONS.TASK.GET_ISSUE_BY_ID, getIssueById),
    takeEvery(
      ACTIONS.TASK.GET_ISSUE_LIST_BY_FACILITY,
      getIssueListByFacilityId
    ),
    takeEvery(
      ACTIONS.TASK.GET_ALL_ISSUES_LIST_BY_FACILITY,
      getAllIssuesListByFacilityId
    ),
    takeEvery(
      ACTIONS.TASK.GET_FEEDBACK_LIST_BY_FACILITY,
      getFeedbackListByFacilityId
    ),
    takeEvery(ACTIONS.TASK.GET_ALL_FACILITY_FEEDBACK, getAllFacilityFeedback),
    takeEvery(
      ACTIONS.TASK.GET_ALL_FEEDBACK_LIST_BY_FACILITY_ID,
      getAllFeedbackListByFacilityId
    ),

    // Template watches
    takeEvery(ACTIONS.TEMPLATE.GET_TEMPLATE_MASTER, getTemplateMaster),
    takeEvery(ACTIONS.TEMPLATE.GET_ALL_QUESTIONS, getAllQuestions),
    takeEvery(ACTIONS.TEMPLATE.ADD_NEW_QUESTION, addNewQuestion),
    takeEvery(ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID, getTemplateById),
    takeEvery(ACTIONS.TEMPLATE.EDIT_QUESTION, editQuestion),
    takeEvery(ACTIONS.TEMPLATE.GET_TEMPLATE_TREE_VIEW, getTemplatesTree),
    takeEvery(ACTIONS.TEMPLATE.POST_MASTER_TEMPLATE, postMasterTemplate),
    takeEvery(ACTIONS.TEMPLATE.GET_TEMPLATE_DOC, getTemplateDoc),
    takeEvery(
      ACTIONS.TEMPLATE.GET_ACTIVE_PATIENT_TEMPLATES,
      getActivePatientTemplates
    ),
    takeEvery(ACTIONS.TEMPLATE.GET_ROUND_SURVEY, getRoundSurvey),

    // User watches
    takeEvery(ACTIONS.USER.GET_USER_ROLES, getUserRoles),
    takeEvery(ACTIONS.USER.GET_FACILITIES, getFacilities),
    takeEvery(ACTIONS.USER.GET_DIVISIONS, getDivisions),
    takeEvery(ACTIONS.USER.POST_USER_ACCESS, postUserAccess),
    takeEvery(ACTIONS.USER.POST_USER_ACCESS_REVOKE, postUserAccessRevoke),
    takeEvery(ACTIONS.USER.ADD_EMPLOYEE_TO_USER, addEmployeesToUser),
    takeEvery(ACTIONS.USER.REMOVE_EMPLOYEE_FROM_USER, removeEmployeeFromUser),
    takeEvery(ACTIONS.USER.REMOVE_USER_AS_DELEGATE, removeUserAsDelegate),
    takeEvery(ACTIONS.USER.POST_UPDATE_USER_PIC, postNewPic),
    takeLatest(ACTIONS.USER.GET_PATIENTS_TO_ROUND, getPatientsToRound),
    takeEvery(ACTIONS.USER.GET_ROUNDS, getRounds),
    takeEvery(
      ACTIONS.USER.GET_PATIENTS_TO_ROUND_FOR_CENSUS,
      getPatientsToRoundForCensus
    ),
    takeEvery(ACTIONS.USER.GET_DISCHARGE_PATIENT, getDischarge),
    takeEvery(ACTIONS.USER.ADD_DELEGATE_TO_USER, addDelegateToUser),
    takeEvery(ACTIONS.USER.REMOVE_DELEGATE_FROM_USER, removeDelegateFromUser),
    takeEvery(ACTIONS.USER.POST_EMPLOYEE_CONFIG, postEmployeeListConfig),
    takeEvery(ACTIONS.USER.GET_EMPLOYEE_NOTES, getEmployeeNotes)
  ]);
}
