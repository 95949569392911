import { call, put } from 'redux-saga/effects';

import { getData, postData } from '../../services/API/axios';
import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';
import { setSelectedPatient } from '../actions/User.action';
import store from '../store';

// Patient sagas

export function* postPatientNote(action) {
  const url = API.postPatientNoteUrl;
  try {
    const { status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Note Posted!', 'success', 1000, 500);
      yield put({
        type: ACTIONS.PATIENT.ADD_NOTE_SUCCESS
      });
      // getting the updated patient data and updating the state
      const getPatientUrl = `${API.getPatientsToRoundUrl}?facilityId=${action.data.facilityId}&accountNum=${action.data.accountNumber}`;
      const patientDataResponse = yield call(getData, getPatientUrl);
      if (patientDataResponse.status === 200) {
        return store.dispatch(setSelectedPatient(patientDataResponse?.data[0])); // updating state
      }
      return undefined;
    }
    return toast('Note Failed to Post!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Note Failed to Post!', 'error', null, 500, true);
  }
}

export function* removePatientNote(action) {
  const url = API.removePatientNoteUrl;
  try {
    const { status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Note Removed!', 'success', 1000, 500);
      yield put({
        type: ACTIONS.PATIENT.REMOVE_NOTE_SUCCESS
      });

      // getting the updated patient data and updating the state
      const getPatientUrl = `${API.getPatientsToRoundUrl}?facilityId=${action.data.facilityId}&accountNum=${action.data.accountNumber}`;
      const patientDataResponse = yield call(getData, getPatientUrl);
      if (patientDataResponse.status === 200) {
        return store.dispatch(setSelectedPatient(patientDataResponse?.data[0])); // updating state
      }
      return undefined;
    }
    return toast('Failed to Remove Note!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Failed to Remove Note!', 'error', null, 500, true);
  }
}

export function* getPatientByAccountNumber(action) {
  const url = `${API.getPatientsToRoundUrl}?facilityId=${action.data.facilityId}&accountNum=${action.data.accountNumber}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      if (data.length) {
        yield put({
          type: ACTIONS.PATIENT.PATIENT_DETAILS_SUCCESS,
          data: data[0]
        });
      } else {
        yield put({
          type: ACTIONS.PATIENT.PATIENT_DETAILS_SUCCESS,
          data: { patientStatus: 'discharged' }
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}
