import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  isRoundStarted: false,
  isRoundCompleted: false,
  fetchingRound: false,
  loading: false,
  currentRound: {},
  pendingFeedbacks: [],
  pendingIssues: [],
  editingRound: false,
  roundViewOnly: false,
  pastRoundId: ''
};

const RoundReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.ROUND.SET_ROUND_STARTED: {
      return {
        ...state,
        isRoundStarted: true,
        isRoundCompleted: false
      };
    }
    // TODO: Duplicate of clear round state below
    case ACTIONS.ROUND.SET_ROUND_CANCELLED: {
      return {
        ...state,
        currentRound: {},
        isRoundStarted: false,
        isRoundCompleted: false,
        editingRound: false,
        loading: false,
        pendingFeedbacks: [],
        pendingIssues: [],
        roundViewOnly: false,
        pastRoundId: ''
      };
    }
    case ACTIONS.ROUND.SET_CURRENT_ROUND: {
      return {
        ...state,
        currentRound: { ...action.data }
      };
    }
    case ACTIONS.ROUND.COMPLETE_ROUND: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIONS.ROUND.GET_ROUND: {
      return {
        ...state,
        fetchingRound: true
      };
    }
    case ACTIONS.ROUND.GET_ROUND_SUCCESS: {
      return {
        ...state,
        fetchingRound: false
      };
    }
    case ACTIONS.ROUND.GET_ROUND_FAILURE: {
      return {
        ...state,
        fetchingRound: false
      };
    }
    case ACTIONS.ROUND.COMPLETE_ROUND_SUCCESS: {
      return {
        ...state,
        isRoundStarted: false,
        isRoundCompleted: true,
        loading: false,
        editingRound: false,
        roundViewOnly: false,
        pendingFeedback: [],
        pendingIssue: [],
        pastRoundId: ''
      };
    }
    case ACTIONS.ROUND.COMPLETE_ROUND_FAILURE: {
      return {
        ...state,
        loading: false,
        pendingFeedback: [],
        pendingIssue: []
      };
    }
    case ACTIONS.ROUND.CLEAR_ROUNDING_STATE: {
      return {
        ...state,
        isRoundStarted: false,
        isRoundCompleted: false,
        loading: false,
        currentRound: {},
        pendingFeedbacks: [],
        pendingIssues: [],
        editingRound: false,
        roundViewOnly: false,
        pastRoundId: ''
      };
    }
    case ACTIONS.ROUND.SET_ROUND_EDITING: {
      return {
        ...state,
        editingRound: true,
        roundViewOnly: false
      };
    }
    case ACTIONS.ROUND.SET_ROUND_VIEW_ONLY: {
      return {
        ...state,
        roundViewOnly: true,
        editingRound: false
      };
    }
    case ACTIONS.ROUND.SET_PAST_ROUND_ID: {
      return {
        ...state,
        pastRoundId: action.data
      };
    }
    case ACTIONS.ROUND.SET_PENDING_FEEDBACK: {
      return {
        ...state,
        pendingFeedbacks: [...state.pendingFeedbacks, { ...action.data }]
      };
    }
    case ACTIONS.ROUND.SET_PENDING_ISSUE: {
      return {
        ...state,
        pendingIssues: [...state.pendingIssues, { ...action.data }]
      };
    }
    default:
      return tempState;
  }
};

export default RoundReducer;
