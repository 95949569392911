import ACTIONS from './actionType';

// Template actions
export const getTemplateMaster = data => {
  return { type: ACTIONS.TEMPLATE.GET_TEMPLATE_MASTER, data };
};

export const clearChosenTemplate = data => {
  return { type: ACTIONS.TEMPLATE.CLEAR_CHOSEN_TEMPLATE, data };
};

export const setChosenTemplate = data => {
  return { type: ACTIONS.TEMPLATE.SET_CHOSEN_TEMPLATE, data };
};

export const getRoundSurvey = data => {
  return { type: ACTIONS.TEMPLATE.GET_ROUND_SURVEY, data };
};

export const setChosenRoundTemplate = data => {
  return { type: ACTIONS.TEMPLATE.SET_CHOSEN_ROUND_TEMPLATE, data };
};

export const getAllQuestions = data => {
  return { type: ACTIONS.TEMPLATE.GET_ALL_QUESTIONS, data };
};

export const setQuestionsOfTemplate = data => {
  return { type: ACTIONS.TEMPLATE.SET_QUESTIONS_OF_TEMPLATE, data };
};

export const addNewQuestion = data => {
  return { type: ACTIONS.TEMPLATE.ADD_NEW_QUESTION, data };
};

export const getTemplateById = data => {
  return { type: ACTIONS.TEMPLATE.GET_TEMPLATE_BY_ID, data };
};
export const editQuestion = data => {
  return { type: ACTIONS.TEMPLATE.EDIT_QUESTION, data };
};

export const editSelectedQuestion = data => {
  return { type: ACTIONS.TEMPLATE.EDIT_SELECTED_QUESTION, data };
};

export const resetEditQuestion = () => {
  return { type: ACTIONS.TEMPLATE.RESET_EDIT_QUESTION };
};

export const getTemplatesTree = data => {
  return { type: ACTIONS.TEMPLATE.GET_TEMPLATE_TREE_VIEW, data };
};

export const postTemplatesConfig = data => {
  return { type: ACTIONS.TEMPLATE.POST_TEMPLATE_CONFIG, data };
};

export const postMasterTemplate = data => {
  return { type: ACTIONS.TEMPLATE.POST_MASTER_TEMPLATE, data };
};

export const updateMasterTemplateSuccessFlags = data => {
  return { type: ACTIONS.TEMPLATE.UPDATE_MASTER_TEMPLATE_SUCCESS_FLAGS, data };
};

export const getTemplateDoc = data => {
  return { type: ACTIONS.TEMPLATE.GET_TEMPLATE_DOC, data };
};

// Fetch active templates for patients round
export const getActivePatientTemplates = data => {
  return { type: ACTIONS.TEMPLATE.GET_ACTIVE_PATIENT_TEMPLATES, data };
};
