// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  facilities: [],
  facilityId: '',
  selectedUnits: [],
  units: [],
  isAllPrevChecked: false,
  isFavsPrevChecked: false,
  unitsSelected: false,
  roundsReportFilter: {},
  isLoadingAllUnits: true,
  allUnitsByFacility: [],
  sasToken: '',
  tokenExpiry: '',
  coreEmpRoundingTemplates: [
    'Standard Employee Rounding',
    '30 Day Rounding Template',
    '60 Day Rounding Template',
    '90 Day Rounding Template'
  ],
  serviceCentralCategories: [
    'Building Maintenance',
    'Building Maintenance (Public)',
    'Building Maintenance (Staff)',
    'Maintenance/Access (Patient/Visitor)',
    'Maintenance/Access (Staff)',
    'GE Biomed',
    'GE Biomed (ServiceCentral)',
    'IT&S',
    'ITG',
    'IT&S (ServiceCentral)',
    'ITG (ServiceCentral)'
  ],
  itAndSCategories: [
    'EMR Issue',
    'Desktop/Laptop Issue',
    'iMobile Issue',
    'Printer/Copier Issue',
    'eMAR Scanner Issue',
    'Other Issue'
  ],
  supportRoundingTypes: ['csrnRounder', 'cnedRounder'],
  validRoundingTypes: ['patient', 'employee', 'csc', 'cned'],
  validTaskRoundingTypes: ['patient', 'employee', 'cned']
};

const ConfigReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.CONFIG.GET_FACILITIES_SUCCESS: {
      return {
        ...state,
        facilities: action.data
      };
    }
    case ACTIONS.CONFIG.GET_FACILITIES_FAILURE: {
      return {
        ...state,
        facilities: []
      };
    }
    case ACTIONS.CONFIG.GET_UNITS_BY_FACILITY_SUCCESS: {
      return {
        ...state,
        ...action.data
      };
    }
    case ACTIONS.CONFIG.SET_SELECTED_UNITS: {
      return {
        ...state,
        selectedUnits: [...action.data]
      };
    }
    case ACTIONS.CONFIG.SET_ALL_PREV_CHECKED: {
      return {
        ...state,
        isAllPrevChecked: action.data,
        unitsSelected: true
      };
    }
    case ACTIONS.CONFIG.SET_FAVS_PREV_CHECKED: {
      return {
        ...state,
        isFavsPrevChecked: action.data,
        unitsSelected: true
      };
    }
    case ACTIONS.CONFIG.GET_ALL_UNITS: {
      return {
        ...state,
        isLoadingAllUnits: true
      };
    }
    case ACTIONS.CONFIG.GET_ALL_UNITS_SUCCESS: {
      return {
        ...state,
        allUnitsByFacility: action.data,
        isLoadingAllUnits: false
      };
    }
    case ACTIONS.CONFIG.GET_SASS_TOKEN_SUCCESS: {
      return {
        ...state,
        sasToken: action.data.sasToken,
        tokenExpiry: action.data.tokenExpirationDateInUTC
      };
    }
    default:
      return tempState;
  }
};

export default ConfigReducer;
