/**
 * External Imports
 */
import { useState } from 'react';

import { NeuTab, NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import { logHelpClick } from '../../../utils/analyticsHelpers';

/**
 * Styling Imports
 */
import {
  PopItem,
  PopIcon,
  InlineRelativeContainer,
  HelpDropDownContainer,
  PopContent
} from '../../Header/mainNav/MainNav.styles';

const HelpDropdown = () => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [, setShowPopover] = useState(false);
  const { logTrackingEvent } = useAnalyticsApi();

  return (
    <>
      <NeuTab
        onBlur={() => setIsHelpOpen(false)}
        navy
        onClick={() => setIsHelpOpen(!isHelpOpen)}
      >
        <div
          id="Help-Icon"
          className="d-flex align-items-center flex-row-reverse"
        >
          Help
          <NeuIcon small>arrow_drop_down</NeuIcon>
        </div>
      </NeuTab>

      <InlineRelativeContainer onBlur={() => setShowPopover(false)}>
        <HelpDropDownContainer showPopover={isHelpOpen}>
          <PopItem
            id="SharePoint"
            button
            onMouseDown={() => {
              logTrackingEvent(logHelpClick('sharepoint'));
              window.open(
                'https://hcahealthcare.sharepoint.com/sites/CORP-CDI/SitePages/Orbit.aspx',
                '_blank'
              );
            }}
          >
            <PopContent>
              Orbit SharePoint Site<PopIcon>launch</PopIcon>
            </PopContent>
          </PopItem>
          <PopItem
            id="Healthstream"
            button
            onMouseDown={() => {
              logTrackingEvent(logHelpClick('health-stream'));
              window.open(
                'https://www.healthstream.com/hlc/common/course/quicklinks.aspx?oid=a86b970c-a5b3-da11-8139-000423acef71&quickLink=YT0xJnRzPTIwMjItMDgtMjlUMTY6MTk6NTImY2lkPTFkM2QyMjFjLTQ2MjUtZWQxMS04MGY3LTAwNTA1NmIxMzRlYyZjdj0w',
                '_blank'
              );
            }}
          >
            <PopContent>
              HealthStream <PopIcon>launch</PopIcon>
            </PopContent>
          </PopItem>
          <PopItem
            id="HR-Employee-Rounding"
            button
            onMouseDown={() => {
              logTrackingEvent(logHelpClick('hr-emp-rounding'));
              window.open(
                'https://connect.medcity.net/web/engret/blog ',
                '_blank'
              );
            }}
          >
            <PopContent>
              HR Employee Rounding <PopIcon>launch</PopIcon>
            </PopContent>
          </PopItem>
        </HelpDropDownContainer>
      </InlineRelativeContainer>
    </>
  );
};

export default HelpDropdown;
