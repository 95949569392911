/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { uniqBy } from 'lodash';
/**
 * Internal Imports
 */
import AdminDischarge from './AdminDischarge';
import Areas from './Areas';
import Encounters from './Encounters';
import Templates from './Templates';
import UsersList from './UsersList';
import QuestionBank from './QuestionBank';
import ReusableTabs from '../../components/reusableTabs/ReusableTabs';
import { subNavAdminLinkObjects } from '../../Route';
/**
 * Global Type Definition Imports
 */
import { RootState } from '../../redux/store';
import { setAdminSearchTerm } from '../../redux/actions/Admin.action';
import { useUserRolesRef } from '../../services/UserRoles/userRoles';
import { useDebounceValue } from '../../utils/debouncers';
import { Tab } from '../../config/interfaces';

const Admin: FC<{ authRoles: Array<string> }> = ({ authRoles }) => {
  const dispatch = useDispatch();
  const { isUserAdmin } = useUserRolesRef();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const debouncedSearchTerm = useDebounceValue(searchTerm, 350);

  const authorizedSubTabs: Tab[] = [];

  subNavAdminLinkObjects.forEach(route => {
    authRoles.forEach(role => {
      if (route.allowedRoles.includes(role)) {
        authorizedSubTabs.push({ label: route.navItemText, path: route.url });
      }
    });
  });
  const uniqueSubTabs: Tab[] = uniqBy(authorizedSubTabs, 'label');

  const reusableTabItems = {
    base: '/admin',
    links: uniqueSubTabs
  };

  useEffect(() => {
    dispatch(setAdminSearchTerm(debouncedSearchTerm));
  }, [debouncedSearchTerm]);

  return (
    <div>
      <ReusableTabs tabItems={reusableTabItems} />
      <Routes>
        <Route
          path="*"
          element={
            isUserAdmin ? <Navigate to="users" /> : <Navigate to="areas" />
          }
        />
        <Route
          path="users"
          element={
            <UsersList searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
          }
        />
        <Route path="encounters" element={<Encounters />} />
        <Route
          path="question-bank"
          element={
            <QuestionBank
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="discharges"
          element={
            <AdminDischarge
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route path="areas" element={<Areas />} />
        <Route path="templates" element={<Templates />} />
      </Routes>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authRoles: state.AuthorizedUser.authorizedUser.roles
});

export default connect(mapReduxStateToProps)(Admin);
