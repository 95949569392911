import { FC, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';

import {
  setSelectedPatient,
  clearSelectedPatient
} from '../../../redux/actions/User.action';

import { RootState } from '../../../redux/store';
import { getPatientByAccountNumber } from '../../../redux/actions/Patient.action';
import { clearTasksByAccountNumber } from '../../../redux/actions/Task.action';
import {
  clearPatientRoundHistory,
  setPatientRoundsPage,
  setPatientRoundsSort
} from '../../../redux/actions/Report.action';
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import Loading from '../../shared/Loading';
import { reverseName } from '../../../utils/helpers';
import { Patient, RoundsReport } from '../../../config/interfaces';

interface PatientRoundsReportTableProps {
  loading: boolean;
  roundsReportList: RoundsReport[];
  selectedPatient: Patient;
  totalTableCount: number;
}
const PatientRoundsReportTable: FC<PatientRoundsReportTableProps> = ({
  loading,
  roundsReportList,
  selectedPatient,
  totalTableCount
}) => {
  const dispatch = useDispatch();

  const [patientToOpen, setPatientToOpen] = useState<RoundsReport | Patient>(
    selectedPatient
  );
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('roundDate');
  const [active, setActive] = useState<string>('Date');
  const [isTableEmpty, setIsTableEmpty] = useState(false);

  const firstUpdate: { current: boolean } = useRef(true); // to detect first render

  const navigate = useNavigate();

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const openPatient = (round: RoundsReport) => {
    dispatch(
      getPatientByAccountNumber({
        facilityId: round.facilityId,
        accountNumber: round.accountNumber
      })
    );
    setPatientToOpen(round);
  };

  useEffect(() => {
    dispatch(clearSelectedPatient());
    return () => {
      dispatch(setPatientRoundsSort({ sortKey: 'roundDate', sortDir: 'desc' }));
    };
  }, []);

  useEffect(() => {
    if (roundsReportList.length === 0) {
      setIsTableEmpty(true);
    } else {
      setIsTableEmpty(false);
    }
  }, [roundsReportList]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (selectedPatient && selectedPatient.patientStatus !== 'discharged') {
      dispatch(clearPatientRoundHistory());
      dispatch(clearTasksByAccountNumber());
      dispatch(setSelectedPatient(selectedPatient));
      if (selectedPatient.accountNum?.substring(0, 2) === 'UL') {
        navigate('/patients/profile', {
          state: { isUnlistedPatient: true },
          replace: true
        });
      } else {
        navigate('/patients/profile', {
          state: { isUnlistedPatient: false },
          replace: true
        });
      }
    }

    if (selectedPatient?.patientStatus === 'discharged') {
      const dischargedPatientDetails = {
        mrn: patientToOpen.patientMRN,
        accountNum: patientToOpen.accountNumber,
        firstName: String(patientToOpen.patient).split(' ')[0],
        lastName: String(patientToOpen.patient).split(' ')[1],
        urn: patientToOpen.patientId,
        lastRoundId: patientToOpen.roundId
      };

      dispatch(clearPatientRoundHistory());
      dispatch(clearTasksByAccountNumber());
      dispatch(setSelectedPatient(dischargedPatientDetails));

      if (String(patientToOpen.accountNumber)?.substring(0, 2) === 'UL') {
        navigate('/patients/profile', {
          state: { isUnlistedPatient: true },
          replace: true
        });
      } else {
        navigate('/patients/profile', {
          state: { isUnlistedPatient: false },
          replace: true
        });
      }
    }
  }, [selectedPatient]);

  useEffect(() => {
    dispatch(setPatientRoundsSort({ sortKey, sortDir }));
  }, [sortDir, sortKey]);

  return (
    <NeuTable>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Unit': '20%', 'Room': '10%', 'Patient & MRN': '20%','Status': '14%', 'Rounded By': '18%', 'Date': '9%', 'Time':'9%'}"
        header
      >
        <NeuTableHeading
          slot="Unit"
          icon={
            !(active === 'Unit') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('unit', 'Unit')}
          active={active === 'Unit'}
        >
          Unit
        </NeuTableHeading>
        <NeuTableHeading
          slot="Room"
          icon={
            !(active === 'Room') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('room', 'Room')}
          active={active === 'Room'}
        >
          Room
        </NeuTableHeading>
        <NeuTableHeading
          slot="Patient & MRN"
          icon={
            !(active === 'Patient & MRN')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('patientName', 'Patient & MRN')}
          active={active === 'Patient & MRN'}
        >
          Patient & MRN
        </NeuTableHeading>
        <NeuTableHeading
          slot="Status"
          icon={
            !(active === 'Status') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('status', 'Status')}
          active={active === 'Status'}
        >
          Status
        </NeuTableHeading>
        <NeuTableHeading
          slot="Rounded By"
          icon={
            !(active === 'Rounded By')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('roundedBy', 'Rounded By')}
          active={active === 'Rounded By'}
        >
          Rounded By
        </NeuTableHeading>
        <NeuTableHeading
          slot="Date"
          icon={
            !(active === 'Date') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('roundDate', 'Date')}
          active={active === 'Date'}
        >
          Date
        </NeuTableHeading>
        <NeuTableHeading slot="Time" icon="none">
          Time
        </NeuTableHeading>
      </NeuTableRow>
      {loading ? (
        <Loading centered={false} extendedTimeOut />
      ) : !loading && isTableEmpty ? (
        <NoDataSplashView type="roundsReport" />
      ) : (
        <NeuTableBody>
          {roundsReportList?.map((round: RoundsReport) => {
            return (
              <NeuTableRow
                columns="{'Unit': '20%', 'Room': '10%', 'Patient & MRN': '20%',  'Status': '14%', 'Rounded By': '18%', 'Date': '9%', 'Time':'9%'}"
                size="large"
                key={round.roundId}
              >
                <NeuLabel style={{ whiteSpace: 'normal' }} slot="Unit">
                  {round.unit}
                </NeuLabel>
                <NeuLabel style={{ whiteSpace: 'normal' }} slot="Room">
                  <div>{round.room}</div>
                </NeuLabel>
                <NeuLabel
                  slot="Patient & MRN"
                  style={{ whiteSpace: 'normal' }}
                  onClick={() => openPatient(round)}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#206DA3',
                        fontWeight: 700
                      }}
                    >
                      {reverseName(
                        String(round.patientFirstName),
                        String(round.patientLastName)
                      )}
                    </div>
                    <div> {round.patientMRN}</div>
                  </div>
                </NeuLabel>
                <NeuLabel slot="Status">{round.status}</NeuLabel>
                <NeuLabel slot="Rounded By">
                  <div>
                    <div>{round.createdUserName}</div>
                    <div>{round.createdUserId}</div>
                  </div>
                </NeuLabel>
                {/* <NeuLabel slot="Status">{round.status}</NeuLabel> */}
                <NeuLabel slot="Date">{round.date}</NeuLabel>
                <NeuLabel slot="Time">{round.time}</NeuLabel>
              </NeuTableRow>
            );
          })}
        </NeuTableBody>
      )}
      {!isTableEmpty && (
        <div>
          <NeuPaginator
            total={totalTableCount}
            rowsPerPage={50}
            onNeuChange={e =>
              e.detail.pageNumber &&
              dispatch(setPatientRoundsPage(e.detail.pageNumber))
            }
          />
        </div>
      )}
    </NeuTable>
  );
};
const mapReduxStateToProps = (state: RootState) => {
  return {
    loading: state.ReportReducer.loading,
    selectedPatient: state.PatientReducer.patientDetails,
    totalTableCount: state.ReportReducer.totalTableCount
  };
};

export default connect(mapReduxStateToProps)(PatientRoundsReportTable);
