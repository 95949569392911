import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CSRNListTable from '../../../components/customTables/csrnListTable';
import Loading from '../../../components/shared/Loading';
import SearchHeader from '../../../components/searchHeader';
import UnlistedEmployeeRoundModal from '../../../components/modals/unlistedEmployeeRoundModal';
import SupportSideNav from '../../../components/sideNav/SupportSideNav';

import { RootState } from '../../../redux/store';
import { getCSRNSubjectsToRoundForList } from '../../../redux/actions/Employee.action';

import { ModalContainer } from '../../../services/ModalPortal/ModalPortal.Styles';
import { SupportContainer } from '../styles/Support.styles';

import {
  AuthUserProps,
  Employee,
  RoundHistory
} from '../../../config/interfaces';

export interface CSRNListProps {
  authorizedUser?: AuthUserProps;
  csrnSubjectsToRound: (Employee & RoundHistory)[];
  isLoading: boolean;
  searchTerm: string;
  showModalFlag: boolean;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const CSRNList: FC<CSRNListProps> = ({
  authorizedUser,
  csrnSubjectsToRound = [],
  isLoading,
  searchTerm,
  showModalFlag,
  setSearchTerm
}) => {
  const dispatch = useDispatch();

  const { csrnFilter = 'all' } = useParams();

  useEffect(() => {
    if (authorizedUser?.hcaid)
      dispatch(
        getCSRNSubjectsToRoundForList({
          hcaid: authorizedUser.hcaid
        })
      );
  }, []);

  return (
    <SupportContainer id="Support-Container">
      <SupportSideNav type="csrn" />
      <div style={{ width: '100%' }}>
        <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {isLoading ? (
          <Loading centered={false} />
        ) : (
          <CSRNListTable
            csrnFilter={csrnFilter}
            searchTerm={searchTerm}
            csrnRounds={csrnSubjectsToRound}
          />
        )}
      </div>

      {showModalFlag && (
        <ModalContainer>
          <UnlistedEmployeeRoundModal />
        </ModalContainer>
      )}
    </SupportContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    csrnSubjectsToRound: state.EmployeeReducer.csrnSubjectsToRoundForList,
    isLoading: state.EmployeeReducer.isLoadingSubjectsToRoundForList,
    showModalFlag: state.UserReducer.showUnlistedEmployeeModalFlag
  };
};

export default connect(mapReduxStateToProps)(CSRNList);
