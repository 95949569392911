import { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import ReusableTabs from '../../components/reusableTabs';
import Login from '../login/Login';
import PatientCensus from './PatientCensus';
import PatientIssues from './PatientIssues';
import PatientFeedback from './PatientFeedback';
import PatientStoplight from './PatientStoplight';
import PatientProfile from './PatientProfile';

import { RootState } from '../../redux/store';
import { setStoplightSearchQuery } from '../../redux/actions/Stoplight.action';
import { setTaskSearchQuery } from '../../redux/actions/Task.action';
import {
  setUserSectionType,
  resetUserSectionType
} from '../../redux/actions/User.action';
import { useDebounceValue } from '../../utils/debouncers';

const Patient: FC<{
  // selectedPatient: IPatient;
}> = () => {
  // let match = useMatch();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const { '*': view } = useParams();
  const { pathname } = useLocation();

  /**
   * In order to identify whether the user is in Employee or Patient section.
   * store variable: userSection
   */
  useEffect(() => {
    dispatch(setUserSectionType('patient'));
    return () => {
      dispatch(resetUserSectionType());
    };
  }, []);

  useEffect(() => {
    if (view?.includes('issues')) dispatch(setTaskSearchQuery(searchTerm));
    if (view?.includes('feedback')) dispatch(setTaskSearchQuery(searchTerm));
    if (view?.includes('stoplight'))
      dispatch(setStoplightSearchQuery(searchTerm));
    return () => {
      dispatch(setTaskSearchQuery(''));
      dispatch(setStoplightSearchQuery(''));
    };
  }, [dbSearchTerm]);

  const reusableTabItems = {
    base: '/patients',
    links: [
      { label: 'Census', path: '/census' },
      { label: 'Issues', path: '/issues' },
      { label: 'Feedback', path: '/feedback' },
      { label: 'Stoplight', path: '/stoplight' }
    ]
  };

  return (
    <div>
      {!pathname.includes('/profile') && (
        <ReusableTabs tabItems={reusableTabItems} />
      )}
      <Routes>
        {/* <Route path="*" element={<Navigate to="census" />} /> */}
        <Route
          path="census"
          element={
            <PatientCensus
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="issues"
          element={
            <PatientIssues
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="feedback"
          element={
            <PatientFeedback
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route
          path="stoplight"
          element={
            <PatientStoplight
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route path="profile" element={<PatientProfile />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </div>
  );
};
const mapReduxStateToProps = (state: RootState) => ({
  selectedPatient: state.UserReducer.selectedPatient
});

export default connect(mapReduxStateToProps)(Patient);
