/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
/**
 * Internal Imports
 */
import HistorySideNav from '../../components/historySideNav/HistorySideNav';
import HistorySideDrawer from '../../components/historySideDrawer';
import Round from '../../components/round/Round';

import { RootState } from '../../redux/store';
import {
  clearEmployeeSearch,
  searchEmployees
} from '../../redux/actions/Employee.action';
import { getEmployeeRoundHistory } from '../../redux/actions/Report.action';
import { getTasksByEmployeeId } from '../../redux/actions/Task.action';
import { getTemplateMaster } from '../../redux/actions/Template.action';
import { clearSelectedEmployee } from '../../redux/actions/User.action';
/**
 * Global Type Definition Imports
 */
import { Employee, RoundHistory, Task } from '../../config/interfaces';

interface EmployeeProfileProps {
  employeeRoundHistory?: RoundHistory;
  issues?: Task[];
  feedback?: Task[];
  selectedEmployee?: Employee;
}

const EmployeeProfile: FC<EmployeeProfileProps> = ({
  employeeRoundHistory,
  issues,
  feedback,
  selectedEmployee
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(
        getEmployeeRoundHistory({
          employeeId: selectedEmployee.hcaid
        })
      );
      dispatch(
        getTasksByEmployeeId({
          employeeId: selectedEmployee?.hcaid,
          roundingTypes: ['employee', 'cned']
        })
      );
      dispatch(getTemplateMaster({ condensed: true, archived: false }));
      dispatch(searchEmployees(selectedEmployee.hcaid));
    } else {
      navigate('/employees/list');
    }
    return () => {
      dispatch(clearEmployeeSearch());
      dispatch(clearSelectedEmployee());
    };
  }, []);

  return (
    <div
      id="Employee-Profile"
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 40,
        flex: 1,
        backgroundColor: 'white',
        height: '100%'
      }}
    >
      <HistorySideNav
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        type="employee"
      />
      <HistorySideDrawer
        selectedTab={selectedTab}
        selectedPerson={selectedEmployee}
        roundHistory={employeeRoundHistory}
        tasks={[...(issues || []), ...(feedback || [])]}
        type="employee"
        setSelectedTab={setSelectedTab}
      />
      <Round type="employee" />
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    selectedEmployee: state.UserReducer.selectedEmployee,
    issues: state.TaskReducer.employeeIssues,
    feedback: state.TaskReducer.employeeFeedback,
    employeeRoundHistory: state.ReportReducer.employeeRoundHistory
  };
};

export default connect(mapReduxStateToProps)(EmployeeProfile);
