import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';

import { NeuButton } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { RootState } from '../../../../redux/store';
import { setRoundCancelled } from '../../../../redux/actions/Round.action';
import { logRoundClick } from '../../../../utils/analyticsHelpers';

interface CancelRoundBtnProps {}

const CancelRoundBtn: FC<CancelRoundBtnProps> = () => {
  const dispatch = useDispatch();

  const { logTrackingEvent } = useAnalyticsApi();

  const handleCancelRound = () => {
    logTrackingEvent(logRoundClick('cancel'));
    dispatch(setRoundCancelled());
  };

  return (
    <NeuButton
      id="Cancel-Round-Button"
      className="mx-2"
      color="red-60"
      fill="raised"
      onClick={handleCancelRound}
    >
      Cancel Round
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser
});

export default connect(mapReduxStateToProps)(CancelRoundBtn);
