import ACTIONS from './actionType';

// Patient actions

export const setRoundStarted = data => {
  return { type: ACTIONS.ROUND.SET_ROUND_STARTED, data };
};

export const setRoundCancelled = data => {
  return { type: ACTIONS.ROUND.SET_ROUND_CANCELLED, data };
};

export const setCurrentRound = data => {
  return { type: ACTIONS.ROUND.SET_CURRENT_ROUND, data };
};

export const completeRound = data => {
  return { type: ACTIONS.ROUND.COMPLETE_ROUND, data };
};

export const setPendingFeedback = data => {
  return { type: ACTIONS.ROUND.SET_PENDING_FEEDBACK, data };
};

export const setPendingIssue = data => {
  return { type: ACTIONS.ROUND.SET_PENDING_ISSUE, data };
};

export const getRound = data => {
  return { type: ACTIONS.ROUND.GET_ROUND, data };
};

export const setRoundEditing = data => {
  return { type: ACTIONS.ROUND.SET_ROUND_EDITING, data };
};
export const setRoundViewOnly = data => {
  return { type: ACTIONS.ROUND.SET_ROUND_VIEW_ONLY, data };
};

export const clearRoundState = data => {
  return { type: ACTIONS.ROUND.CLEAR_ROUNDING_STATE, data };
};
