import {
  FC,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format, sub } from 'date-fns';

import { NeuInputChangeEventDetail } from '@neutron/core';
import { NeuIcon, NeuTablist, NeuTab } from '@neutron/react';

import {
  EncountersContainer,
  EncountersSidePanelContainer,
  EncountersSidePanelSubheader,
  EncounterSidePanelInput,
  EncounterSidePanelInputLabels,
  DropDownSearchContainer,
  DropItem,
  BackText,
  EncounterHistorySidePanelLabels,
  EncounterHistorySidePanelData,
  JsonViewContainer
} from './styles/AdminViews.styles';
import { ReusableToolbar } from '../../components/reusableTabs/ReusableTabs.styles';
import EncountersTable from '../../components/customTables/encountersTable';
import EncountersHistoryTable from '../../components/customTables/encountersHistoryTable';
import Round from '../../components/round';

import { RootState } from '../../redux/store';
import { getEncounters } from '../../redux/actions/Admin.action';
import { clearRoundState, getRound } from '../../redux/actions/Round.action';

import { getPatientByAccountNumber } from '../../redux/actions/Patient.action';
import { clearPatientRoundHistory } from '../../redux/actions/Report.action';
import { clearTasksByAccountNumber } from '../../redux/actions/Task.action';
import {
  getFacilities,
  setSelectedPatient
} from '../../redux/actions/User.action';

import { date } from '../../utils/helpers';

import {
  AuthUserProps,
  Encounter,
  Facility,
  Patient,
  RoundHistory
} from '../../config/interfaces';

interface EncountersProps {
  authorizedUser?: AuthUserProps;
  facilities: Facility[];
  encountersList: Encounter[];
  patientRoundHistory: RoundHistory[];
  selectedPatient: Patient;
}

interface UserInputsT {
  startday: string;
  endday: string;
  facilityId?: string;
}

const Encounters: FC<EncountersProps> = ({
  authorizedUser,
  facilities,
  encountersList,
  patientRoundHistory,
  selectedPatient
}) => {
  const dispatch = useDispatch();
  // selected facility
  const [facility, setFacility] = useState<Facility | null | undefined>(null);

  // value to display in the facility input field
  const [facilityFieldValue, setFacilityFieldValue] = useState<
    string | number | null | undefined
  >(null);

  // filtered facility records
  const [facilitySearchResult, setFacilitySearchResult] =
    useState<Facility[]>(facilities);

  // show or hide facility list
  const [facilityPopState, setFacilityPopState] = useState<boolean>(false);

  // other user inputs
  const [userInputs, setUserInputs] = useState<UserInputsT>({
    startday: format(new Date(), 'yyyy-MM-dd'),
    endday: format(new Date(), 'yyyy-MM-dd')
  });

  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [encounterHistory, setEncounterHistory] = useState<Encounter | null>(
    null
  );
  const [roundHistory, setRoundHistory] = useState<RoundHistory[] | null>(null);
  const [historyViewType, setHistoryViewType] = useState<string>('table');
  const [showRound, setShowRound] = useState<boolean>(false);

  const firstUpdate: MutableRefObject<boolean> = useRef(true); // to detect first render
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFacilities());
  }, []);

  useEffect(() => {
    const userFacility = facilities?.find((item: Facility) => {
      return item.facilityId === authorizedUser?.facilityId;
    });
    setFacilityFieldValue(userFacility?.facility);
    setFacility(userFacility); // initially, auto populate facility
  }, [facilities]);

  // get encounters when user user input changes
  useEffect(() => {
    const userInputsCopy = { ...userInputs }; // shallow copying  the state object
    userInputsCopy.startday = `${userInputsCopy.startday.replace(
      /-/g,
      ''
    )}000000`; // time added as start of the day
    userInputsCopy.endday = `${userInputsCopy.endday.replace(/-/g, '')}235959`; // time added as end of the day
    userInputsCopy.facilityId = facility?.facilityId; // selected facility id added
    if (facility) dispatch(getEncounters(userInputsCopy));
  }, [userInputs, facility]);

  useEffect(() => {
    setRoundHistory(patientRoundHistory);
  }, [patientRoundHistory]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (selectedPatient && selectedPatient.patientStatus !== 'discharged') {
      dispatch(clearPatientRoundHistory());
      dispatch(clearTasksByAccountNumber());
      dispatch(setSelectedPatient(selectedPatient));
      if (selectedPatient.accountNum?.substring(0, 2) === 'UL') {
        navigate('/patients/profile', {
          state: { isUnlistedPatient: true },
          replace: true
        });
      } else {
        navigate('/patients/profile', {
          state: { isUnlistedPatient: false },
          replace: true
        });
      }
    }

    if (selectedPatient?.patientStatus === 'discharged') {
      const dischargedPatientDetails = {
        mrn: encounterHistory?.mrn,
        accountNum: encounterHistory?.accountnumber,
        firstName: encounterHistory?.firstname,
        lastName: encounterHistory?.lastname,
        urn: encounterHistory?.urn
      };

      dispatch(clearPatientRoundHistory());
      dispatch(clearTasksByAccountNumber());
      dispatch(setSelectedPatient(dischargedPatientDetails));
      navigate('/patients/profile', {
        state: { isUnlistedPatient: false },
        replace: true
      });
    }
  }, [selectedPatient]);

  const gotoRoundPage = (round: RoundHistory) => {
    dispatch(clearRoundState([]));
    if (round.roundId) {
      dispatch(
        getRound({
          roundId: round.roundId,
          fromHistorydrawer: false
        })
      );
      setShowRound(true);
    }

    dispatch(
      getPatientByAccountNumber({
        facilityId: round.facilityId,
        accountNumber: round.accountNumber
      })
    );

    // setPatientToOpen(round);
  };

  const handleFacilitySearch = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const { value } = e.target as HTMLNeuInputElement;
    setFacilityFieldValue(value); // to display value inside the input field
    const facilitySearchResultLocal: Facility[] = [];

    facilities.find((o: Facility) => {
      const searchValue = value as string;
      const searchRes = o.facility
        ?.toLowerCase()
        .search(searchValue?.toLowerCase());
      if (searchRes !== -1) facilitySearchResultLocal.push(o); // if item matches with search term
      return null;
    });

    setFacilitySearchResult(facilitySearchResultLocal);
  };

  const handleFacilitySelected = (selectedFacility: Facility) => {
    setFacility(selectedFacility);
    setFacilityFieldValue(selectedFacility.facility);
    setFacilityPopState(false);
  };

  const handleInputs = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const { name, value } = e.target as HTMLNeuInputElement;

    setUserInputs({ ...userInputs, [name]: value });
  };

  const handleHideHistory = () => {
    setShowHistory(false);
    setEncounterHistory(null);
    setHistoryViewType('table');
    setRoundHistory([]);
    setShowRound(false);
    // dispatch(clearRoundState([]));
  };

  const getAccessPlacesById = (id?: string) => {
    const obj = facilities.find((item: Facility) => item.facilityId === id);
    return obj ? obj.facility : '';
  };

  const selectHistoryViewType = (e: MouseEvent) => {
    const { id: typeSelected } = e.target as HTMLNeuTabElement;

    if (typeSelected === 'tab-button-table') {
      setHistoryViewType('table');
    }
    if (typeSelected === 'tab-button-json') {
      setHistoryViewType('json');
    }
  };

  const minStartDate = useMemo(
    () => date.convertToDayOfYear(sub(new Date(), { years: 2 })),
    []
  );
  const maxStartDate = useMemo(
    () =>
      userInputs.endday
        ? userInputs.endday
        : date.convertToDayOfYear(new Date()),
    [userInputs.endday]
  );
  const minEndDate = useMemo(
    () =>
      userInputs.endday
        ? userInputs.startday
        : date.convertToDayOfYear(new Date()),
    [userInputs.startday]
  );
  const maxEndDate = useMemo(() => date.convertToDayOfYear(new Date()), []);

  return (
    <EncountersContainer id="Admin-Encounter">
      <EncountersSidePanelContainer>
        {showHistory ? (
          <div style={{ width: 'maxContent' }}>
            <BackText onClick={handleHideHistory}>
              <NeuIcon>chevron_left</NeuIcon> Back to Encounters
            </BackText>
          </div>
        ) : null}
        {!showHistory ? (
          <>
            <EncountersSidePanelSubheader>
              Encounters
            </EncountersSidePanelSubheader>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <EncounterSidePanelInputLabels>
                Facility
              </EncounterSidePanelInputLabels>
              <div
                onBlur={() => setFacilityPopState(false)}
                onFocus={() => setFacilityPopState(true)}
              >
                <EncounterSidePanelInput
                  id="Encounter-Side-Panel-Facility-Input"
                  value={facilityFieldValue}
                  name="facility"
                  type="search"
                  clearOnEdit
                  clearable
                  onNeuChange={handleFacilitySearch}
                />
              </div>
              <DropDownSearchContainer showPopover={facilityPopState}>
                {facilitySearchResult.map((item: Facility) => (
                  <DropItem
                    id={`Encounter-Side-Panel-Facility-Item-${item.facilityId}`}
                    onMouseDown={() => handleFacilitySelected(item)}
                    key={item.facilityId}
                  >
                    {item.facility}
                  </DropItem>
                ))}
              </DropDownSearchContainer>

              <EncounterSidePanelInputLabels>MRN</EncounterSidePanelInputLabels>
              <EncounterSidePanelInput
                id="Encounter-Side-Panel-MRN"
                name="mrn"
                onNeuChange={handleInputs}
              />

              <EncounterSidePanelInputLabels>
                Account Number
              </EncounterSidePanelInputLabels>
              <EncounterSidePanelInput
                id="Encounter-Side-Panel-Account"
                name="accountNumber"
                onNeuChange={handleInputs}
              />

              <EncounterSidePanelInputLabels>
                First Name
              </EncounterSidePanelInputLabels>
              <EncounterSidePanelInput
                id="Encounter-Side-Panel-First-Name"
                name="firstName"
                onNeuChange={handleInputs}
              />

              <EncounterSidePanelInputLabels>
                Last Name
              </EncounterSidePanelInputLabels>
              <EncounterSidePanelInput
                id="Encounter-Side-Panel-Last-Name"
                name="lastName"
                onNeuChange={handleInputs}
              />
            </div>
            <EncountersSidePanelSubheader>
              Date Range
            </EncountersSidePanelSubheader>
            <EncounterSidePanelInputLabels>
              Starting
            </EncounterSidePanelInputLabels>
            <EncounterSidePanelInput
              id="Encounter-Side-Panel-Start-Day"
              type="date"
              name="startday"
              onNeuChange={handleInputs}
              value={userInputs.startday}
              min={minStartDate}
              max={maxStartDate}
            />
            <EncounterSidePanelInputLabels>
              Ending
            </EncounterSidePanelInputLabels>
            <EncounterSidePanelInput
              id="Encounter-Side-Panel-End-Day"
              type="date"
              name="endday"
              onNeuChange={handleInputs}
              value={userInputs.endday}
              min={minEndDate}
              max={maxEndDate}
            />
          </>
        ) : (
          // encounter history side nav
          <>
            <EncountersSidePanelSubheader>
              Encounters
            </EncountersSidePanelSubheader>
            <div style={{ paddingLeft: '10px' }}>
              <EncounterHistorySidePanelLabels>
                Patient Name
              </EncounterHistorySidePanelLabels>
              <EncounterHistorySidePanelData>
                {`${encounterHistory?.firstname} ${encounterHistory?.lastname}`}
              </EncounterHistorySidePanelData>
              <EncounterHistorySidePanelLabels>
                Facility
              </EncounterHistorySidePanelLabels>
              <EncounterHistorySidePanelData>
                {getAccessPlacesById(encounterHistory?.facility)}
              </EncounterHistorySidePanelData>
              <EncounterHistorySidePanelLabels>
                MRN
              </EncounterHistorySidePanelLabels>
              <EncounterHistorySidePanelData>
                {encounterHistory?.mrn}
              </EncounterHistorySidePanelData>
              <EncounterHistorySidePanelLabels>
                Account Number
              </EncounterHistorySidePanelLabels>
              <EncounterHistorySidePanelData>
                {encounterHistory?.accountnumber}
              </EncounterHistorySidePanelData>
              <EncounterHistorySidePanelLabels>
                Admit Date
              </EncounterHistorySidePanelLabels>
              <EncounterHistorySidePanelData>
                {encounterHistory?.admittime
                  ? date.datePrettier(
                      (
                        parseInt(encounterHistory?.admittime, 10) - 1000000
                      ).toString()
                    )
                  : '--/--/--'}
              </EncounterHistorySidePanelData>
              <EncounterHistorySidePanelLabels>
                Discharge Date
              </EncounterHistorySidePanelLabels>
              <EncounterHistorySidePanelData>
                {encounterHistory?.dischargetime
                  ? date.datePrettier(encounterHistory?.dischargetime)
                  : '--/--/--'}
              </EncounterHistorySidePanelData>
            </div>
          </>
        )}
      </EncountersSidePanelContainer>
      {/* <div> */}
      {!showHistory ? (
        <EncountersTable
          encountersList={encountersList}
          setEncounterHistory={setEncounterHistory}
          setShowHistory={setShowHistory}
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 206px)',
            overflow: 'hidden'
          }}
        >
          {!showRound ? (
            <ReusableToolbar>
              <NeuTablist color="plain">
                <NeuTab
                  active={historyViewType === 'table'}
                  tab="table"
                  onClick={selectHistoryViewType}
                >
                  Table View
                </NeuTab>
                <NeuTab
                  active={historyViewType === 'json'}
                  tab="json"
                  onClick={selectHistoryViewType}
                >
                  JSON
                </NeuTab>
              </NeuTablist>
            </ReusableToolbar>
          ) : (
            <NeuIcon
              style={{ float: 'right', marginRight: '50px', cursor: 'pointer' }}
              onClick={() => setShowRound(false)}
              small
              right
              btn
            >
              close
            </NeuIcon>
          )}
          {!showRound ? (
            <div>
              {historyViewType === 'table' ? (
                <EncountersHistoryTable
                  encounterData={encounterHistory}
                  roundHistory={roundHistory}
                  gotoRoundPage={gotoRoundPage}
                />
              ) : (
                <pre
                  style={{
                    fontFamily: 'monospace',
                    fontWeight: '400',
                    fontSize: '16px',
                    lineHeight: '26px',
                    letterSpacing: '0px',
                    textAlign: 'left'
                  }}
                >
                  <span>
                    <JsonViewContainer>
                      {JSON.stringify(encounterHistory, null, 4)}
                    </JsonViewContainer>
                  </span>
                </pre>
              )}
            </div>
          ) : (
            <Round type="patient" hideCloseButton />
          )}
        </div>
      )}
    </EncountersContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    facilities: state.ConfigReducer.facilities,
    authorizedUser: state.AuthorizedUser.authorizedUser,
    encountersList: state.AdminReducer.encounters,
    patientRoundHistory: state.ReportReducer.patientRoundHistory,
    selectedPatient: state.PatientReducer.patientDetails
  };
};

export default connect(mapReduxStateToProps)(Encounters);
