/**
 * External Imports
 */
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  NeuIcon,
  NeuButton,
  NeuCardTitle,
  NeuOption,
  NeuLabel,
  NeuList,
  NeuItem,
  NeuCheckbox
} from '@neutron/react';
import { uniq, orderBy } from 'lodash';
import {
  NeuInputChangeEventDetail,
  NeuTextareaChangeEventDetail
} from '@neutron/core';
/**
 * Internal Imports
 */
import { RootState } from '../../../redux/store';
import {
  addNewQuestion,
  editQuestion,
  resetEditQuestion
} from '../../../redux/actions/Template.action';
import MultiSelectDropdown from '../../MultiSelect/MultiSelectDropdown';
import QuestionPart from './questionPart/QuestionPart';
import { Dropdown } from '../../shared/dropdowns';
import { qTags } from './QuestionTags';
import { handleModal } from '../../../services/ModalPortal/utils';
import { formatRoundingType } from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import {
  ConditionListProp,
  QuestionList,
  QuestionPartProp
} from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  CardHeader,
  ModalCardContent,
  InputHeading,
  ModalInput,
  AddQuestionCard,
  AddQuestionPartCard
} from './AddQuestionModal.styles';

const { v1: uuidv1 } = require('uuid');

interface MetaProps {
  questionTitle?: string | undefined;
  questionTags?: string[];
  roundingType: string;
  archived?: boolean;
  questionId?: string;
  questionCondition?: string[];
}

interface ModalProps {
  questions: QuestionList[];
  selectedQuestionId: string;
  openModalInEditMode: boolean;
}

const roundingType = ['Patient', 'Employee', 'CSRN', 'CNEd'];

const AddQuestionModal: FC<ModalProps> = ({
  questions,
  selectedQuestionId,
  openModalInEditMode
}) => {
  const dispatch = useDispatch();
  const [showButton, setShowButton] = useState<boolean | '' | undefined>(false);
  const [isOpenAddQuestionPart, setIsOpen] = useState<boolean>(false);
  const [defaultQuestionTags, setDefaultQuestionTags] = useState<{}[]>([]);
  const [defaultQuestionConditions, setDefaultQuestionConditions] = useState<
    {}[]
  >([]);
  const [conditionsList, setConditionsList] = useState<ConditionListProp[]>([]);
  const [categoryList, setCategoryList] = useState<ConditionListProp[]>([]);
  const [newQuestionParts, setNewQuestionParts] = useState<QuestionPartProp[]>(
    []
  );
  const [newQuestionMeta, setNewQuestionMeta] = useState<MetaProps>({
    roundingType: roundingType[0]
  });
  const [open, setOpen] = useState(false);
  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  const handleQuestionPromptChange = (
    id: string,
    e: CustomEvent<NeuTextareaChangeEventDetail>
  ) => {
    const { value } = e.target as HTMLNeuInputElement;
    setNewQuestionParts(
      newQuestionParts.map((part: QuestionPartProp) => {
        if (part.questionPartId === id) {
          const tempPart = part;
          tempPart.question = value?.toString() || '';
          return tempPart;
        }
        return part;
      })
    );
  };

  const closeAnswer = (id: string) => {
    const tempNewQuestionParts = newQuestionParts.map(
      (part: QuestionPartProp) => {
        const tempPart = part;
        tempPart.options = tempPart.options.filter(
          option => option.optionId !== id
        );
        return tempPart;
      }
    );
    setNewQuestionParts([...tempNewQuestionParts]);
  };

  const handleInputs = (e: CustomEvent<KeyboardEvent>) => {
    const { name, value } = e.target as HTMLNeuInputElement;
    switch (name) {
      case 'questionTitle':
        setNewQuestionMeta({
          ...newQuestionMeta,
          questionTitle: value?.toString() || ''
        });
        break;
      default: {
        console.log('error');
      }
    }
  };

  const handleDropdownChange = useCallback(
    (e: React.BaseSyntheticEvent<MouseEvent>, name: string) => {
      const { value } = e.target;
      switch (name) {
        case 'roundingType': {
          setNewQuestionMeta({ ...newQuestionMeta, roundingType: value });
          break;
        }
        default: {
          console.log('error');
        }
      }
    },
    [newQuestionMeta]
  );

  const handleClose = () => {
    dispatch(resetEditQuestion());
    handleModal(modalContentRef);
  };

  const validatedQuestionParts = () => {
    // Validation: Empty charge or flag must be empty string, not null
    return newQuestionParts.map((part: QuestionPartProp) => {
      part.options.map(option => {
        const tempOption = option;
        tempOption.charge = option.charge || '';
        tempOption.flag = option.flag || '';
        return tempOption;
      });

      return part;
    });
  };

  const questionToSave = () => {
    return {
      ...newQuestionMeta,
      questionParts: validatedQuestionParts()
    };
  };

  const saveAndClose = () => {
    const formattedQuestionToSave = questionToSave();

    if (openModalInEditMode) {
      dispatch(editQuestion(formattedQuestionToSave));
      // appInsights.trackEvent({
      //   name: 'Profile_Issues_Edited'
      // });
    } else {
      dispatch(addNewQuestion(formattedQuestionToSave));
    }

    handleModal(modalContentRef);
  };

  const cleanupList = (list: string[]) => {
    // Trim each item
    const mappedList = list.map(item => item.trim());

    // Remove duplicates
    const uniqueList = uniq(mappedList);

    // Order by item name, ignoring case
    const orderedList = orderBy(uniqueList, (item: string) =>
      item.toLowerCase()
    );

    // Filter empty items
    return orderedList.filter(v => !!v);
  };

  const createBlankQuestion = () => {
    setNewQuestionMeta({
      questionId: uuidv1(),
      questionTitle: '',
      roundingType: 'patient',
      questionTags: [],
      questionCondition: [],
      archived: false
    });
    setNewQuestionParts([]);
  };

  const handleChargeChange = (partId: string, optionId: string, e: string) => {
    setNewQuestionParts(
      newQuestionParts.map((questionPart: QuestionPartProp) => {
        if (questionPart.questionPartId === partId) {
          questionPart.options.map(option => {
            if (option.optionId === optionId) {
              const tempOption = option;
              tempOption.charge = e;
              return tempOption;
            }
            return option;
          });
        }
        return questionPart;
      })
    );
  };

  const addQuestionPartData = (type: string) => {
    const newPart = {
      questionPartId: uuidv1(),
      question: '',
      questionType: type,
      questionCondition: [],
      mustShow: false,
      config: {},
      options: [],
      min: '',
      max: ''
    };

    if (type === 'scale') {
      // This was hard-coded in old Orbit. Leave out for now.
      // newPart.step = 1
      newPart.min = '0';
      newPart.max = '10';
    }

    newQuestionParts.push(newPart);
    setIsOpen(false);
  };
  const addAnswer = (questionPartId: string) => {
    setNewQuestionParts(
      newQuestionParts.map((part: QuestionPartProp) => {
        if (part.questionPartId === questionPartId) {
          part.options.push({
            title: null,
            value: null,
            optionId: uuidv1(),
            charge: 'positive',
            flag: null
          });
        }
        return part;
      })
    );
  };
  const handleAddQuestionPart = () => {
    setIsOpen(true);
  };

  const removeQuestionPart = (id: string) => {
    setNewQuestionParts(
      newQuestionParts.filter(
        (part: QuestionPartProp) => part.questionPartId !== id
      )
    );
  };

  const changeOptionTitle = (
    questionPartId: string,
    optionId: string,
    title: string
  ) => {
    setNewQuestionParts(
      newQuestionParts.map((part: QuestionPartProp) => {
        if (part.questionPartId === questionPartId) {
          part.options.map(option => {
            const tempOption = option;
            if (option.optionId === optionId) {
              tempOption.title = title;
              tempOption.value = title.toLowerCase();
            }
            return tempOption;
          });
        }
        return part;
      })
    );
  };

  const addFlagToAnswer = (
    questionPartId: string,
    optionId: string,
    flag: string
  ) => {
    setNewQuestionParts(
      newQuestionParts.map((part: QuestionPartProp) => {
        if (part.questionPartId === questionPartId) {
          part.options.map(option => {
            const tempOption = option;
            if (option.optionId === optionId) {
              tempOption.flag = flag;
            }
            return tempOption;
          });
        }
        return part;
      })
    );
  };

  const addConditionToQuestionPart = (
    id: string,
    conditions: ConditionListProp[]
  ) => {
    setNewQuestionParts(
      newQuestionParts.map((part: QuestionPartProp) => {
        if (part.questionPartId === id) {
          while (part.questionCondition.length > 0) {
            part.questionCondition.pop();
          }
          conditions.map((condition: ConditionListProp) => {
            return part.questionCondition.push(condition.value);
          });
        }
        return part;
      })
    );
  };

  const addTagToQuestion = (tag: ConditionListProp[]) => {
    const tags = tag.map((category: ConditionListProp) => {
      return category.value;
    });
    setNewQuestionMeta({ ...newQuestionMeta, questionTags: tags });
  };

  const addConditionToQuestion = (conditions: ConditionListProp[]) => {
    const formattedConditions = conditions.map(
      (condition: ConditionListProp) => {
        return condition.value;
      }
    );
    setNewQuestionMeta({
      ...newQuestionMeta,
      questionCondition: formattedConditions
    });
  };

  const handleQuestionArchiveChange = (
    e: React.BaseSyntheticEvent<MouseEvent>
  ) => {
    const archive = e.target?.checked;
    // appInsights.trackEvent({
    //   name: 'Question_Archived'
    // });
    setNewQuestionMeta({ ...newQuestionMeta, archived: archive });
  };

  const updateMaxMin = (
    id: string,
    maxmin: string,
    e: CustomEvent<NeuInputChangeEventDetail>
  ) => {
    const value = e.target as HTMLInputElement;
    setNewQuestionParts(
      newQuestionParts.map((part: QuestionPartProp) => {
        if (part.questionPartId === id) {
          const tempPart = part;
          if (maxmin === 'max') {
            tempPart.max = value;
          } else if (maxmin === 'min') {
            tempPart.min = value;
          }
          return tempPart;
        }
        return part;
      })
    );
  };

  const handleMustShowFlag = (
    id: string,
    e: React.BaseSyntheticEvent<MouseEvent>
  ) => {
    setNewQuestionParts(
      newQuestionParts.map((part: QuestionPartProp) => {
        if (part.questionPartId === id) {
          const tempPart = part;
          tempPart.mustShow = e.target.checked;
          return tempPart;
        }
        return part;
      })
    );
  };

  const moveItem = (from: number, to: number) => {
    // remove `from` item and store it
    const tempNewQuestionParts = newQuestionParts;
    const f = tempNewQuestionParts.splice(from, 1)[0];
    // insert stored item into position `to`
    tempNewQuestionParts.splice(to, 0, f);
    setNewQuestionParts([...tempNewQuestionParts]);
  };

  const moveUpDown = (id: string, updown: string) => {
    newQuestionParts.every((part: QuestionPartProp) => {
      if (part.questionPartId === id) {
        let to = null;
        if (updown === 'up') {
          if (newQuestionParts.indexOf(part) !== 0) {
            to = newQuestionParts.indexOf(part) - 1;
          }
        } else if (
          newQuestionParts.length - 1 !==
          newQuestionParts.indexOf(part)
        ) {
          to = newQuestionParts.indexOf(part) + 1;
        }
        if (to !== null) {
          moveItem(newQuestionParts.indexOf(part), to);
          return false;
        }
      }
      return part;
    });
  };

  const readyToSave = () => {
    const parts = newQuestionParts;
    const meta = newQuestionMeta;
    // const questionIsArchived = this.questionIsArchived

    // If archiving, bypass all validation rules
    // if (questionIsArchived) return true

    // To be valid, the question must
    // 1. Have a title
    // 2. Have at least one part
    // 3. Each part must have a question prompt
    // 4. Multi questions must have at least one answer option
    return (
      meta.questionTitle &&
      parts.length > 0 &&
      parts.every((p: QuestionPartProp) => {
        let flag;
        if (p.questionType === 'multi') {
          // Multi questions must have at least one option
          flag =
            p.question &&
            p.options.length &&
            p.options.every(option => option.value);
        } else {
          flag = p.question;
        }
        return flag;
      })
    );
  };

  useEffect(() => {
    setShowButton(readyToSave());
  }, [newQuestionParts, newQuestionMeta]);

  useEffect(() => {
    const q = questions;
    const conditionsListUncleaned: string[] = [];

    if (q && q.length > 0) {
      q.forEach(
        (question: {
          questionCondition?: string[];
          questionParts: QuestionPartProp[];
        }) => {
          // Look inside the question itself for conditions
          if (
            question.questionCondition &&
            question.questionCondition.length > 0
          ) {
            question.questionCondition.forEach(condition => {
              conditionsListUncleaned.push(condition);
            });
          }

          // Look inside each question part for conditions
          if (question.questionParts && question.questionParts.length > 0) {
            question.questionParts.forEach(part => {
              if (part.questionCondition && part.questionCondition.length > 0) {
                part.questionCondition.forEach((condition: string) => {
                  conditionsListUncleaned.push(condition.toString());
                });
              }
            });
          }
        }
      );
    }

    // Trim whitespace, filter duplicates, order by first letter case-insensitive, filter out empty
    const formatedConditions = cleanupList(conditionsListUncleaned).map(
      condition => {
        return { id: condition, value: condition };
      }
    );
    setConditionsList(formatedConditions);

    // start with patient tags & add employee ones to list
    const tagsList: string[] = qTags;

    // commenting out & just going forward with tags on list for now
    // if (q && q.length > 0) {
    //   const empQs = q.filter(
    //     (question: any = {}) => question.roundingType !== 'patient'
    //   );

    //   empQs.forEach((question: { questionTags: any[] }) => {
    //     if (question.questionTags && question.questionTags.length > 0) {
    //       question.questionTags.forEach(tag => {
    //         tagsList.push(tag);
    //       });
    //     }
    //   });
    // }

    // Trim whitespace, filter duplicates, order by first letter case-insensitive, filter out empty
    const formatedCategories = cleanupList(tagsList).map(category => {
      return { id: category, value: category };
    });
    setCategoryList(formatedCategories);
  }, [questions]);

  useEffect(() => {
    createBlankQuestion();
  }, []);

  useEffect(() => {
    if (openModalInEditMode) {
      const arrSelectedQuestion = questions.filter(
        (val: QuestionList) => val.questionId === selectedQuestionId
      );
      if (arrSelectedQuestion.length > 0) {
        const question = arrSelectedQuestion[0];
        setNewQuestionMeta({
          questionId: question.questionId,
          questionTitle: question.questionTitle,
          roundingType: question.roundingType,
          questionTags: question.questionTags,
          questionCondition: question.questionCondition,
          archived: question.archived
        });
        setNewQuestionParts([...question.questionParts]);
        if (question.questionTags) {
          setDefaultQuestionTags(
            question.questionTags.map((val: string) => {
              return { id: val, value: val };
            })
          );
        }
        if (question.questionCondition) {
          setDefaultQuestionConditions(
            question.questionCondition.map((val: string) => {
              return { id: val, value: val };
            })
          );
        }
      }
    }
  }, [selectedQuestionId, openModalInEditMode, questions]);

  const addQuestionPart = () => {
    return (
      <NeuList id="Add-Question-Part-modal" color="white" lines="inset">
        <NeuItem>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              onClick={() => addQuestionPartData('info')}
              onKeyDown={() => addQuestionPartData('info')}
              role="button"
              tabIndex={0}
            >
              <NeuLabel
                color="primary"
                style={{ fontWeight: 700, fontSize: 16 }}
              >
                Free Text
              </NeuLabel>
              <NeuLabel style={{ marginBottom: 5 }}>
                Prompt Text (no answer required)
              </NeuLabel>
            </div>
            <NeuIcon
              color="primary"
              style={{ marginLeft: 50, height: 24, width: 24 }}
            >
              add
            </NeuIcon>
          </div>
        </NeuItem>
        <NeuItem>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              onClick={() => addQuestionPartData('single')}
              onKeyDown={() => addQuestionPartData('single')}
              role="button"
              tabIndex={0}
            >
              <NeuLabel
                color="primary"
                style={{ fontWeight: 700, fontSize: 16 }}
              >
                Single
              </NeuLabel>
              <NeuLabel style={{ marginBottom: 5 }}>Select one answer</NeuLabel>
            </div>
            <NeuIcon
              color="primary"
              style={{ marginLeft: 145, height: 24, width: 24, marginTop: 10 }}
            >
              add
            </NeuIcon>
          </div>
        </NeuItem>
        <NeuItem>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              onClick={() => addQuestionPartData('multi')}
              onKeyDown={() => addQuestionPartData('multi')}
              role="button"
              tabIndex={0}
            >
              <NeuLabel
                color="primary"
                style={{ fontWeight: 700, fontSize: 16 }}
              >
                Multi
              </NeuLabel>
              <NeuLabel style={{ marginBottom: 5 }}>
                Select one or more answers
              </NeuLabel>
            </div>
            <NeuIcon
              color="primary"
              style={{ marginLeft: 80, height: 24, width: 24, marginTop: 10 }}
            >
              add
            </NeuIcon>
          </div>
        </NeuItem>
        <NeuItem>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              onClick={() => addQuestionPartData('scale')}
              onKeyDown={() => addQuestionPartData('scale')}
              role="button"
              tabIndex={0}
            >
              <NeuLabel
                color="primary"
                style={{ fontWeight: 700, fontSize: 16 }}
              >
                Scale
              </NeuLabel>
              <NeuLabel>Select a number from a range</NeuLabel>
              <NeuLabel style={{ marginBottom: 5 }}>e.g. 6 out of 10</NeuLabel>
            </div>
            <NeuIcon
              color="primary"
              style={{ marginLeft: 67, height: 24, width: 24, marginTop: 15 }}
            >
              add
            </NeuIcon>
          </div>
        </NeuItem>
        <NeuItem>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              onClick={() => addQuestionPartData('text')}
              onKeyDown={() => addQuestionPartData('text')}
              role="button"
              tabIndex={0}
            >
              <NeuLabel
                color="primary"
                style={{ fontWeight: 700, fontSize: 16 }}
              >
                Text
              </NeuLabel>
              <NeuLabel style={{ marginBottom: 5 }}>
                Enter free form text
              </NeuLabel>
            </div>
            <NeuIcon
              color="primary"
              style={{ marginLeft: 130, height: 24, width: 24, marginTop: 10 }}
            >
              add
            </NeuIcon>
          </div>
        </NeuItem>
      </NeuList>
    );
  };

  return (
    <AddQuestionCard
      className="show-modal"
      id="Add-Question-Modal"
      type="submit"
      maxWidth="500px"
      minHeight="100vh"
      maxHeight="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        <NeuCardTitle>
          <NeuLabel style={{ color: '#093865', marginLeft: 1 }}>
            {openModalInEditMode ? 'Edit Question' : 'New Question'}
          </NeuLabel>
        </NeuCardTitle>
        <NeuButton fill="flat" onClick={handleClose}>
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      <ModalCardContent>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'inline-block',
              flexDirection: 'column',
              width: '35%'
            }}
          >
            <InputHeading>Rounding Type</InputHeading>
            <Dropdown
              classes="pl-0 pt-0"
              name="roundingType"
              open={open}
              placeholder={formatRoundingType(newQuestionMeta.roundingType)}
              styles={{ minWidth: '180px' }}
              width="120%"
              setOpen={setOpen}
            >
              {roundingType.map((type: string) => (
                <NeuOption
                  className={`dropdown-hover-item${
                    newQuestionMeta.roundingType === type.toLowerCase()
                      ? ' selected'
                      : ''
                  }`}
                  value={type.toLowerCase()}
                  key={type}
                  onClick={e => handleDropdownChange(e, 'roundingType')}
                >
                  {type}
                </NeuOption>
              ))}
            </Dropdown>
          </div>
          <div
            style={{
              position: 'relative',
              flexDirection: 'column',
              display: 'inline-block',
              width: '65%',
              marginLeft: '10px'
            }}
          >
            <InputHeading>Question Title</InputHeading>
            <ModalInput
              id="Question-Title-Input"
              type="text"
              name="questionTitle"
              autocomplete="off"
              placeholder="e.g. Pain Level"
              inputmode="text"
              enterkeyhint="next"
              autocorrect="off"
              spellcheck
              value={newQuestionMeta.questionTitle || ''}
              onNeuInput={handleInputs}
            />
          </div>
        </div>
        <div>
          <InputHeading>Question Conditions</InputHeading>
          <MultiSelectDropdown
            options={conditionsList}
            defaultselection={defaultQuestionConditions}
            selected={addConditionToQuestion}
            id="questionConditions"
            showArrow
            searchable={false}
            chipsInside={false}
            placeholderText="Select one or more..."
          />
          <InputHeading>Question Tags</InputHeading>
          <MultiSelectDropdown
            options={categoryList}
            defaultselection={defaultQuestionTags}
            selected={addTagToQuestion}
            id="questionCategory"
            showArrow
            searchable={false}
            chipsInside={false}
            placeholderText="Select one or more..."
          />
        </div>
        {newQuestionParts.map((part: QuestionPartProp) => {
          return (
            <QuestionPart
              editQuestion={openModalInEditMode}
              part={part}
              conditionsList={conditionsList}
              moveUpDown={moveUpDown}
              removeQuestionPart={removeQuestionPart}
              handleQuestionPromptChange={handleQuestionPromptChange}
              addConditionToQuestionPart={addConditionToQuestionPart}
              handleMustShowFlag={handleMustShowFlag}
              updateMaxMin={updateMaxMin}
              changeOptionTitle={changeOptionTitle}
              addFlagToAnswer={addFlagToAnswer}
              handleChargeChange={handleChargeChange}
              closeAnswer={closeAnswer}
              addAnswer={addAnswer}
              key={part.questionPartId}
            />
          );
        })}
        <div
          style={{
            width: 124,
            height: 24
          }}
        >
          <NeuButton
            id="Add-Question-Question-Part-Button"
            color="primary"
            size="small"
            onClick={() => handleAddQuestionPart()}
            style={{
              width: 124,
              height: 24
            }}
          >
            Add Question Part
          </NeuButton>
        </div>
      </ModalCardContent>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: 15,
          marginBottom: 10,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 5 }}>
          {openModalInEditMode ? (
            <div>
              <NeuCheckbox
                onClick={e => handleQuestionArchiveChange(e)}
                checked={newQuestionMeta.archived}
              />
              <NeuLabel
                style={{ fontWeight: 400, fontSize: 14, marginLeft: 5 }}
              >
                Archive
              </NeuLabel>
            </div>
          ) : null}
        </div>
        <div>
          <NeuButton
            id="Unlisted-Patient-Begin-Round-Button"
            color="primary"
            onClick={showButton ? saveAndClose : undefined}
            disabled={!showButton}
          >
            Save Question
          </NeuButton>
        </div>
      </div>
      {isOpenAddQuestionPart && (
        <AddQuestionPartCard id="Add-Question-Part-Modal">
          {addQuestionPart()}
        </AddQuestionPartCard>
      )}
    </AddQuestionCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    questions: state.TemplateReducer.allQuestions,
    units: state.ConfigReducer.units,
    selectedQuestionId: state.TemplateReducer.selectedQuestionId,
    openModalInEditMode: state.TemplateReducer.editQuestion
  };
};

export default connect(mapReduxStateToProps)(AddQuestionModal);
