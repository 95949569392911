/**
 * External Imports
 */
import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import {
  NeuIcon,
  NeuButton,
  NeuCardTitle,
  NeuDivider,
  NeuImage
} from '@neutron/react';
import { NeuInputChangeEventDetail } from '@neutron/core';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import { BlueChip } from '../../shared/chips/blueChip';
import IssueSubHeader from '../reusableModalPieces/subHeader';
import SearchView from '../reusableModalPieces/searchView';
import { toast } from '../../../services/Toast';

import { RootState } from '../../../redux/store';
import { getRound } from '../../../redux/actions/Round.action';
import { getTemplateMaster } from '../../../redux/actions/Template.action';
import {
  clearEmployeeSearch,
  searchEmployeesToShare
} from '../../../redux/actions/Employee.action';
import {
  getEmployeeSharedWith,
  resetEmployeeSharedWith,
  getDetailsByTicketNumber,
  clearTicketDetails,
  clearSingleIssueFromEmail,
  clearIssueById,
  postNewTask,
  addTaskToSharingQ,
  clearModalState
} from '../../../redux/actions/Task.action';
import {
  getStoplightById,
  clearStoplightResponse
} from '../../../redux/actions/Stoplight.action';
import { setToastType } from '../../../redux/actions/Toast.action';
import {
  setSelectedPatient,
  getPatientsToRound,
  clearPatientsToRound,
  setSelectedEmployee,
  getEmployeeNotes
} from '../../../redux/actions/User.action';

import { logTaskClick } from '../../../utils/analyticsHelpers';
import { useDebounceValue } from '../../../utils/debouncers';
import {
  date,
  toTitleCase,
  formatPhoneNumber,
  abbreviatedName
} from '../../../utils/helpers';
import { handleModal } from '../../../services/ModalPortal/utils';
/**
 * Global Type Definition Imports
 */
import {
  AuthUserProps,
  Employee,
  Issue,
  Stoplight,
  TaskPost,
  Unit
} from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  ViewIssueCard,
  CardHeader,
  ModalCardContent,
  ModalFooter,
  TitleSpanView,
  IssueViewDiv,
  IssueViewSubDiv,
  IssueViewDiv2Column,
  LabelHeading,
  FooterDiv,
  LabelContentPatient,
  LabelContentBold,
  FooterText,
  TicketNumber,
  ModalInput,
  DropItem,
  DropDownSearchContainer,
  ViewStoplightsClickableLabel
} from './AddIssueModal.styles';
import { ChipWrapper } from '../feedbackModal/FeedbackModal.styles';
/**
 * Static File Imports
 */
import serviceCentralLogoImg from '../../../assets/images/service_central_icon.png';

interface IIssueModalViewProps {
  associatedEmployee: Employee;
  associatedPatient: {}[];
  authorizedUser: AuthUserProps;
  employeeSharedWith: [];
  issueData: TaskPost;
  searchedEmployeesToShare: [];
  serviceCentralCategories: string[];
  stoplightResponse?: Stoplight;
  tempModalState: { redirect: boolean };
  ticketDetails: {
    url: string;
  };
  units: Unit[];
}

const IssueModalView: FC<IIssueModalViewProps> = ({
  associatedEmployee,
  associatedPatient,
  authorizedUser,
  employeeSharedWith,
  issueData,
  searchedEmployeesToShare,
  serviceCentralCategories,
  stoplightResponse,
  tempModalState,
  ticketDetails,
  units
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [sharedWithDisplay, setSharedWithDisplay] = useState<Array<string>>([]);
  const [taskObject, setTaskObject] = useState<TaskPost>(issueData);
  const [shareObject, setShareObject] = useState<{
    taskId: string;
    userName: string;
    email: string[];
  }>({ taskId: '', userName: '', email: [] });
  const [sharedSearchState, setSharedSearchState] = useState<boolean>(true);
  const [sharedSearchTerm, setSharedSearchTerm] = useState('');
  const [sharedPopState, setSharedPopState] = useState<boolean>(false);
  const [view, setView] = useState<'info' | 'search'>('info');

  const localStorageDeepLinkPath = window.localStorage.getItem('task');
  const isCompleteTask =
    localStorageDeepLinkPath?.includes('task/complete') ||
    pathname.includes('task/complete');

  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  const { logTrackingEvent } = useAnalyticsApi();

  const isAnimated = useMemo(
    () => (!tempModalState?.redirect ? 'show-modal' : ''),
    [tempModalState]
  );

  const dbSharedSearchTerm = useDebounceValue(sharedSearchTerm, 250);

  // shared with search
  useEffect(() => {
    if (sharedSearchTerm.length > 2) {
      dispatch(searchEmployeesToShare(dbSharedSearchTerm));
    }
  }, [dbSharedSearchTerm]);

  useEffect(() => {
    // Get employee details in order to show on chips if the sharedWith array is not empty.
    if (issueData.sharedWith && issueData.sharedWith.length > 0) {
      issueData.sharedWith.forEach(employeeId => {
        dispatch(getEmployeeSharedWith(employeeId));
      });
    }
  }, [issueData.sharedWith]);

  useEffect(() => {
    if (issueData.stoplightId) {
      dispatch(getStoplightById(issueData.stoplightId));
    }
  }, [issueData.stoplightId]);

  useEffect(() => {
    if (issueData.ticketNumber) {
      dispatch(getDetailsByTicketNumber(issueData.ticketNumber));
    }
  }, [issueData.ticketNumber]);

  useEffect(() => {
    if (searchedEmployeesToShare.length > 0 && sharedSearchTerm.length > 0) {
      setSharedPopState(true);
    }
  }, [searchedEmployeesToShare]);

  useEffect(() => {
    const alreadySharedEmployees: string[] = employeeSharedWith.map(
      (emp: Employee) => {
        const empId = emp.employeeId.toUpperCase();
        return `${emp.firstName} ${emp.lastName} ${empId}`;
      }
    );
    setSharedWithDisplay([...alreadySharedEmployees]);
  }, [employeeSharedWith]);

  const handleClose = () => {
    logTrackingEvent(logTaskClick('issues', 'close'));
    dispatch(clearModalState());
    dispatch(clearStoplightResponse());
    dispatch(resetEmployeeSharedWith());
    dispatch(clearTicketDetails());
    handleModal(modalContentRef);
    if (isCompleteTask) {
      navigate(
        taskObject.roundingType === 'employee'
          ? '/employees/issues'
          : '/patients/issues'
      );
    }
  };
  const handleRemoveSharedWith = (emp: string) => {
    let removedEmpName: string;
    const spaces = emp.split(' ').length - 1;
    if (spaces === 2) {
      removedEmpName = emp.split(' ').slice(0, 2).join('.');
    } else {
      const splittedArray = emp.split(' ');
      removedEmpName = `${splittedArray[0] + splittedArray[1]}.${
        splittedArray[2]
      }`;
    }
    const removedEmpId = emp.split(/[\s+]+/).pop() || '';
    // Update sharedWith in task object
    const newSharedWith = taskObject.sharedWith.filter((item: string) => {
      return item.includes(removedEmpId?.toLowerCase()) === false;
    });
    setTaskObject({
      ...taskObject,
      sharedWith: newSharedWith
    });

    // update Emails for sharing
    const emailsAfterRemove = shareObject.email.filter((email: string) => {
      const existingString = email.split('@')[0];
      return existingString !== removedEmpName;
    });
    setShareObject({ ...shareObject, email: emailsAfterRemove });
  };
  const setEmployeeToShare = (emp: Employee) => {
    const empId = emp.employeeId;
    const empExists = taskObject.sharedWith.includes(empId);
    // if the employee already exists in share, do not add again
    if (!empExists) {
      setTaskObject({
        ...taskObject,
        sharedWith: [...taskObject.sharedWith, empId]
      });
      setSharedWithDisplay([
        ...sharedWithDisplay,
        `${emp.firstName} ${emp.lastName} ${empId}`
      ]);
      setShareObject({
        taskId: taskObject.taskId || '',
        userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`,
        email: [...shareObject.email, emp.email]
      });
    } else {
      // TODO future development add a toast here saying 'user already selected'
      console.log('User already selected');
    }

    setSharedSearchState(false);
    setSharedSearchTerm('');
    setSharedPopState(false);
    dispatch(clearEmployeeSearch());
  };

  const handleRemoveAttachStoplight = (
    e: MouseEvent<HTMLNeuIconElement>,
    stoplight: Stoplight
  ) => {
    e.stopPropagation();
    dispatch(setToastType({ type: 'Delete Stoplight' }));
    if (stoplight.id === taskObject.stoplightId) {
      toast(
        `Are you sure you would like to un-attach stoplight ${stoplight.friendlyId}?`,
        'info',
        null,
        400,
        true,
        null,
        () => {
          dispatch(clearStoplightResponse());
        }
      );
    } else {
      dispatch(clearStoplightResponse());
    }
  };

  // In order to clear data when unmount
  useEffect(() => {
    return () => {
      dispatch(resetEmployeeSharedWith());
      dispatch(clearIssueById());
      dispatch(clearSingleIssueFromEmail());
    };
  }, []);

  const unitName = useMemo(() => {
    const foundUnit: Unit[] = units.filter(
      (unit: Unit) => unit.unitId === issueData.unitId
    );
    return foundUnit[0]?.unit;
  }, [issueData, units]);

  const openTicketDetailsTab = () => {
    if (ticketDetails && ticketDetails.url) {
      window.open(ticketDetails.url, '_blank');
    }
  };

  /* Code block for navigating to round from issue modal */
  useEffect(() => {
    if (
      issueData.roundingType === 'patient' &&
      issueData?.facilityId &&
      issueData?.accountNum
    ) {
      dispatch(
        getPatientsToRound({
          facilityId: issueData.facilityId,
          accountNum: issueData.accountNum
        })
      );
    }
    if (issueData.roundingType === 'employee' && issueData?.employeeId) {
      if (associatedEmployee?.hcaid !== issueData.employeeId.toLowerCase()) {
        dispatch(
          setSelectedEmployee({
            hcaid: issueData.employeeId.toLowerCase(),
            firstName: issueData.employeeFirstName,
            lastName: issueData.employeeLastName,
            email: ''
          })
        );
      }
    }
    if (issueData?.roundId) {
      dispatch(
        getRound({
          roundId: issueData.roundId,
          fromHistorydrawer: false
        })
      );
      dispatch(getTemplateMaster({ condensed: true, archived: false }));
    }
  }, [issueData]);

  useEffect(() => {
    if (associatedPatient && associatedPatient.length === 1) {
      dispatch(setSelectedPatient(associatedPatient[0]));
    }
  }, [associatedPatient]);

  const handleRedirect = () => {
    if (issueData?.roundId) {
      if (issueData.roundingType === 'patient') {
        if (associatedPatient && associatedPatient.length === 1) {
          dispatch(clearPatientsToRound());
          handleModal(modalContentRef);
          if (issueData?.accountNum?.substring(0, 2) === 'UL') {
            navigate('/patients/profile', {
              state: { isUnlistedPatient: true },
              replace: true
            });
          } else {
            navigate('/patients/profile', {
              state: { isUnlistedPatient: false },
              replace: true
            });
          }
        }
      } else {
        dispatch(getEmployeeNotes(issueData.employeeId));
        handleModal(modalContentRef);
        navigate('/employees/profile');
      }
    }
  };
  /* Code block for navigating to round from issue modal */
  const handleSearching = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    setSharedSearchState(true);
    setSharedSearchTerm(target.value);
  };

  // adding stoplight to issue
  const setStoplightToAttach = (stoplight: Stoplight) => {
    if (stoplight.id) {
      dispatch(getStoplightById(stoplight.id));
    }
  };

  const handlePost = () => {
    const payload = {
      ...taskObject,
      updated: date.now(),
      stoplightId: stoplightResponse?.id || ''
    };
    delete payload.discussion;
    if (shareObject.userName) {
      dispatch(addTaskToSharingQ(shareObject));
    }
    dispatch(postNewTask(payload));
    setShareObject({ taskId: '', userName: '', email: [] });
    setSharedWithDisplay([]);
    dispatch(clearStoplightResponse());
    dispatch(clearModalState());
    handleModal(modalContentRef);
    if (isCompleteTask) {
      navigate(
        taskObject.roundingType === 'employee'
          ? '/employees/issues'
          : '/patients/issues'
      );
    }
  };
  return (
    <ViewIssueCard
      className={isAnimated}
      id="Modal-View-Issues"
      type="submit"
      height="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        <NeuCardTitle>
          <TitleSpanView>
            {issueData.roundingType &&
              `${toTitleCase(issueData.roundingType)} | ${
                serviceCentralCategories.includes(
                  issueData.category as string
                ) ||
                (issueData.taskSubtype &&
                  serviceCentralCategories.includes(issueData.taskSubtype))
                  ? 'Service Central Issue'
                  : 'Issue'
              }`}
          </TitleSpanView>
        </NeuCardTitle>
        <NeuButton
          id="Issue-Modal-Close-Button"
          fill="flat"
          onClick={handleClose}
        >
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      <IssueSubHeader
        completed={issueData.completed}
        items={stoplightResponse?.id}
        type="issue"
        view={view}
        setView={setView}
      />
      {view === 'info' ? (
        <ModalCardContent>
          <LabelHeading>Issue ID</LabelHeading>
          <LabelContentBold>
            {issueData?.friendlyId ? issueData.friendlyId : 'Unknown'}
          </LabelContentBold>
          <IssueViewDiv2Column>
            {issueData.roundingType === 'patient' ? (
              <IssueViewSubDiv>
                <LabelHeading>Patient</LabelHeading>
                <LabelContentPatient
                  onClick={() => handleRedirect()}
                  cursorType={
                    !!(
                      issueData &&
                      issueData?.roundId &&
                      associatedPatient.length === 1
                    )
                  }
                  color={
                    issueData &&
                    issueData?.roundId &&
                    associatedPatient.length === 1
                      ? '#206DA3'
                      : '#191919'
                  }
                >
                  {`${
                    !issueData.patientLastName || !issueData.patientFirstName
                      ? 'Unknown'
                      : abbreviatedName(
                          issueData.patientFirstName,
                          issueData.patientLastName
                        )
                  }`}
                </LabelContentPatient>
              </IssueViewSubDiv>
            ) : (
              <div>
                <LabelHeading>Employee</LabelHeading>
                <LabelContentPatient
                  onClick={() => handleRedirect()}
                  cursorType={
                    !!(issueData && issueData?.roundId && issueData.employeeId)
                  }
                  color={
                    issueData && issueData?.roundId && issueData.employeeId
                      ? '#206DA3'
                      : '#191919'
                  }
                >
                  {`${
                    issueData.employeeLastName
                      ? `${toTitleCase(issueData.employeeLastName)}, `
                      : ''
                  }
                  ${
                    issueData.employeeFirstName
                      ? toTitleCase(issueData.employeeFirstName)
                      : ''
                  }`}
                  {`${
                    !issueData.employeeLastName && !issueData.employeeFirstName
                      ? 'Unknown'
                      : ''
                  }`}
                  {`${
                    issueData.employeeId
                      ? ` | ${issueData.employeeId.toUpperCase()}`
                      : ''
                  }`}
                </LabelContentPatient>
              </div>
            )}

            {issueData.roundingType === 'patient' && (
              <IssueViewSubDiv>
                <LabelHeading>Room</LabelHeading>
                <LabelContentBold>
                  {issueData.room
                    ? `${issueData.room}${issueData.bed || ''}`
                    : 'N/A'}
                </LabelContentBold>
              </IssueViewSubDiv>
            )}
          </IssueViewDiv2Column>
          <IssueViewDiv2Column>
            <IssueViewSubDiv>
              <LabelHeading>Creator</LabelHeading>
              <LabelContentBold>
                {issueData.userFullName || 'Unknown'}
              </LabelContentBold>
            </IssueViewSubDiv>
          </IssueViewDiv2Column>
          {(serviceCentralCategories.includes(issueData.category as string) ||
            (issueData.taskSubtype &&
              serviceCentralCategories.includes(issueData.taskSubtype))) && (
            <IssueViewDiv2Column>
              <IssueViewSubDiv>
                <LabelHeading>Ticket Number</LabelHeading>
                <LabelContentBold>
                  <TicketNumber onClick={openTicketDetailsTab}>
                    {issueData.ticketNumber}
                  </TicketNumber>
                </LabelContentBold>
              </IssueViewSubDiv>
              <IssueViewSubDiv>
                <LabelHeading>Callback Number</LabelHeading>
                <LabelContentBold>
                  {issueData && issueData.callback
                    ? formatPhoneNumber(issueData.callback)
                    : issueData.u_best_contact_info
                    ? formatPhoneNumber(issueData?.u_best_contact_info)
                    : 'Unknown'}
                </LabelContentBold>
              </IssueViewSubDiv>
            </IssueViewDiv2Column>
          )}
          {/* TODO: should cat and subcat only show with service central */}
          <IssueViewDiv>
            <LabelHeading>Category</LabelHeading>
            <LabelContentBold className="d-flex align-items-center">
              {(serviceCentralCategories.includes(
                issueData.category as string
              ) ||
                (issueData.taskSubtype &&
                  serviceCentralCategories.includes(
                    issueData.taskSubtype
                  ))) && (
                <NeuImage
                  width="25px"
                  alt="service central logo"
                  src={serviceCentralLogoImg}
                />
              )}
              {issueData.category || issueData.taskSubtype || 'Unknown'}
            </LabelContentBold>
          </IssueViewDiv>
          {issueData.subCategory && (
            <IssueViewDiv>
              <LabelHeading>Subcategory</LabelHeading>
              <LabelContentBold>
                {issueData.subCategory || 'Unknown'}
              </LabelContentBold>
            </IssueViewDiv>
          )}
          {/* *********************************************************** */}
          {issueData.roundingType === 'patient' ? (
            <IssueViewDiv>
              <LabelHeading>Unit</LabelHeading>
              <LabelContentBold>{`${
                issueData.unitId ? unitName : 'Unknown'
              }`}</LabelContentBold>
            </IssueViewDiv>
          ) : (
            <IssueViewDiv>
              <LabelHeading>Department</LabelHeading>
              <LabelContentBold>
                {issueData.department || 'Unknown'}
              </LabelContentBold>
            </IssueViewDiv>
          )}
          <IssueViewDiv>
            <LabelHeading>Details</LabelHeading>
            <LabelContentBold>
              {issueData.description || 'Unknown'}
            </LabelContentBold>
          </IssueViewDiv>
          <IssueViewDiv>
            <LabelHeading>Share With</LabelHeading>
            <div onBlur={() => setSharedPopState(false)}>
              {(sharedWithDisplay &&
                sharedWithDisplay.length > 0 &&
                sharedWithDisplay.map(emp => {
                  return (
                    <ChipWrapper
                      key={emp}
                      onClick={() => handleRemoveSharedWith(emp)}
                    >
                      <BlueChip key={emp} name={emp} />
                    </ChipWrapper>
                  );
                })) ||
                []}
              <div style={{ position: 'relative' }}>
                <ModalInput
                  type="search"
                  name="sharedWith"
                  autocomplete="off"
                  placeholder="Start typing name or 3-4 ID"
                  inputmode="search"
                  enterkeyhint="search"
                  autocorrect="off"
                  value={sharedSearchState ? sharedSearchTerm : null}
                  onNeuChange={sharedSearchState ? handleSearching : () => {}}
                  onNeuFocus={() => setSharedSearchState(true)}
                />
                <DropDownSearchContainer showPopover={sharedPopState}>
                  {searchedEmployeesToShare.map((emp: Employee) => (
                    <div key={emp.employeeId}>
                      <DropItem
                        onMouseDown={() => setEmployeeToShare(emp)}
                        button
                      >
                        <p>
                          {emp.firstName.toUpperCase()}{' '}
                          {emp.lastName.toUpperCase()}
                        </p>
                        <p style={{ fontSize: '14px' }}>{emp.employeeId}</p>
                        <p style={{ fontSize: '14px' }}>{emp.title}</p>
                      </DropItem>
                      {searchedEmployeesToShare.length > 1 && <NeuDivider />}
                    </div>
                  ))}
                </DropDownSearchContainer>
              </div>
            </div>
          </IssueViewDiv>
          <IssueViewDiv>
            <ViewStoplightsClickableLabel
              className="mt-4 mb-3"
              onClick={() => setView('search')}
            >
              {issueData.stoplightId ||
              (issueData.completed && !issueData.stoplightId)
                ? 'View Attached Stoplight'
                : 'Attach to Stoplight'}
            </ViewStoplightsClickableLabel>
          </IssueViewDiv>
        </ModalCardContent>
      ) : (
        <ModalCardContent id="Issues-Modal-Stoplight-Card" className="pb-4">
          <SearchView
            attachedDisplay={
              stoplightResponse && Object.keys(stoplightResponse).length > 0
                ? ([stoplightResponse] as (Issue & Stoplight)[])
                : ([] as (Issue & Stoplight)[])
            }
            attachToItem={issueData as TaskPost & Stoplight}
            attachToItemCompleted={taskObject.completed}
            prevAttached={stoplightResponse?.id ? [stoplightResponse.id] : []}
            type="stoplight"
            handleRemoveAttached={handleRemoveAttachStoplight}
            setItemsToAttach={setStoplightToAttach}
          />
        </ModalCardContent>
      )}
      <ModalFooter className="px-1" small>
        <FooterDiv>
          {(serviceCentralCategories.includes(issueData.category as string) ||
            (issueData.taskSubtype &&
              serviceCentralCategories.includes(issueData.taskSubtype))) && (
            <>
              <FooterText className="pr-1">
                {issueData.completed
                  ? 'This issue was marked complete in Service \nCentral.'
                  : 'This issue is being worked in Service Central.'}
              </FooterText>
              <NeuButton onClick={() => handlePost()}>Save</NeuButton>
            </>
          )}
        </FooterDiv>
      </ModalFooter>
    </ViewIssueCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    associatedEmployee: state.UserReducer.selectedEmployee,
    associatedPatient: state.UserReducer.patientsToRound,
    authorizedUser: state.AuthorizedUser.authorizedUser,
    employeeSharedWith: state.TaskReducer.employeeSharedWith,
    issueData: state.ModalReducer.modalData,
    modalType: state.ModalReducer.modalType,
    searchedEmployeesToShare: state.EmployeeReducer.searchedEmpsToShare,
    searchedStoplight: state.StoplightReducer.searchedStoplight,
    serviceCentralCategories: state.ConfigReducer.serviceCentralCategories,
    stoplightResponse: state.StoplightReducer.stoplightById,
    tempModalState: state.TaskReducer.tempModalState,
    ticketDetails: state.TaskReducer.ticketDetails,
    units: state.ConfigReducer.units
  };
};

export default connect(mapReduxStateToProps)(IssueModalView);
