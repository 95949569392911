import { FC, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import Loading from '../../shared/Loading';

import { RootState } from '../../../redux/store';
import {
  clearEmployeeSearch,
  searchEmployees
} from '../../../redux/actions/Employee.action';
import {
  clearEmployeeRoundHistory,
  setEmployeeRoundsPage,
  setEmployeeRoundsSort
} from '../../../redux/actions/Report.action';
import { clearTasksByEmployeeId } from '../../../redux/actions/Task.action';
import {
  clearSelectedEmployee,
  getEmployeeNotes,
  setSelectedEmployee
} from '../../../redux/actions/User.action';

import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';

import { logRoundProfileFromReports } from '../../../utils/analyticsHelpers';
import { date } from '../../../utils/helpers';

import { Employee, EmpRoundsReport } from '../../../config/interfaces';

interface EmployeeRoundsReportTableProps {
  loading: boolean;
  modalType: string;
  roundsReportList: EmpRoundsReport[];
  searchedEmployees: Employee[];
  selectedEmployee: Employee;
  totalTableCount: number;
}
const EmployeeRoundsReportTable: FC<EmployeeRoundsReportTableProps> = ({
  loading,
  modalType,
  roundsReportList,
  searchedEmployees,
  selectedEmployee,
  totalTableCount
}) => {
  const dispatch = useDispatch();

  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('roundDate');
  const [active, setActive] = useState<string>('Date');
  const [isTableEmpty, setIsTableEmpty] = useState(false);

  const firstUpdate: { current: boolean } = useRef(true); // to detect first render

  const { logTrackingEvent } = useAnalyticsApi();

  const navigate = useNavigate();

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const openEmployee = (round: EmpRoundsReport) => {
    logTrackingEvent(logRoundProfileFromReports('employee'));
    dispatch(searchEmployees(round?.employeeId));
  };

  useEffect(() => {
    dispatch(clearSelectedEmployee());
    return () => {
      dispatch(clearEmployeeSearch());
      dispatch(
        setEmployeeRoundsSort({ sortKey: 'roundDate', sortDir: 'desc' })
      );
    };
  }, []);

  useEffect(() => {
    if (roundsReportList.length === 0) {
      setIsTableEmpty(true);
    } else {
      setIsTableEmpty(false);
    }
  }, [roundsReportList]);

  useEffect(() => {
    if (firstUpdate.current) {
      return;
    }
    if (
      searchedEmployees.length > 0 &&
      !(modalType === 'Filter Rounds Report')
    ) {
      dispatch(setSelectedEmployee(searchedEmployees[0]));
    }
  }, [searchedEmployees]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (selectedEmployee) {
      dispatch(clearEmployeeRoundHistory());
      dispatch(clearTasksByEmployeeId());
      dispatch(setSelectedEmployee(selectedEmployee));
      dispatch(getEmployeeNotes(selectedEmployee.hcaid));
      navigate('/employees/profile', {
        replace: true
      });
    }
  }, [selectedEmployee]);

  useEffect(() => {
    dispatch(setEmployeeRoundsSort({ sortKey, sortDir }));
  }, [sortDir, sortKey]);

  return (
    <NeuTable>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Dept': '24%', 'Employee': '18%', 'Round Status': '11%', 'Round Type': '20%', 'Rounded By': '17%', 'Date': '10%'}"
        header
      >
        <NeuTableHeading
          slot="Dept"
          icon={
            !(active === 'Dept') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('employeeDept', 'Dept')}
          active={active === 'Dept'}
        >
          Department
        </NeuTableHeading>
        <NeuTableHeading
          slot="Employee"
          icon={
            !(active === 'Employee')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('employeeName', 'Employee')}
          active={active === 'Employee'}
        >
          Employee
        </NeuTableHeading>
        <NeuTableHeading
          slot="Round Status"
          icon={
            !(active === 'Round Status')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('status', 'Round Status')}
          active={active === 'Round Status'}
        >
          Status
        </NeuTableHeading>
        <NeuTableHeading
          slot="Round Type"
          icon={
            !(active === 'Round Type')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('templateName', 'Round Type')}
          active={active === 'Round Type'}
        >
          Round Type
        </NeuTableHeading>
        <NeuTableHeading
          slot="Rounded By"
          icon={
            !(active === 'Rounded By')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('roundedBy', 'Rounded By')}
          active={active === 'Rounded By'}
        >
          Rounded By
        </NeuTableHeading>
        <NeuTableHeading
          slot="Date"
          icon={
            !(active === 'Date') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('roundDate', 'Date')}
          active={active === 'Date'}
        >
          Last Round
        </NeuTableHeading>
      </NeuTableRow>
      {loading ? (
        <Loading centered={false} />
      ) : !loading && isTableEmpty ? (
        <NoDataSplashView type="roundsReport" />
      ) : (
        <NeuTableBody>
          {roundsReportList?.map((round: EmpRoundsReport) => {
            return (
              <NeuTableRow
                columns="{'Dept': '24%', 'Employee': '18%', 'Round Status': '11%', 'Round Type': '20%', 'Rounded By': '17%', 'Date': '10%'}"
                size="large"
                key={round.roundId}
              >
                <NeuLabel style={{ whiteSpace: 'normal' }} slot="Dept">
                  {round.department}
                </NeuLabel>
                <NeuLabel
                  slot="Employee"
                  style={{ whiteSpace: 'normal' }}
                  // TODO: Are we gonna link to employee?
                  onClick={() => openEmployee(round)}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: '#206DA3',
                        fontWeight: 700
                      }}
                    >
                      {round.employee}
                    </div>
                  </div>
                </NeuLabel>
                <NeuLabel slot="Round Status">{round.status}</NeuLabel>
                <NeuLabel slot="Round Type">{round.templateName}</NeuLabel>
                <NeuLabel slot="Rounded By">
                  <div>
                    <div>{round.roundedBy}</div>
                    {/* TODO: are we including rounders id */}
                    {/* <div>{round.createdUserId}</div> */}
                  </div>
                </NeuLabel>
                <NeuLabel slot="Date">
                  {round?.date && round.date.length === 14
                    ? date.dayOfYr(round?.date)
                    : ''}
                </NeuLabel>
              </NeuTableRow>
            );
          })}
        </NeuTableBody>
      )}
      {!isTableEmpty && (
        <div>
          <NeuPaginator
            total={totalTableCount}
            rowsPerPage={50}
            onNeuChange={e =>
              e.detail.pageNumber &&
              dispatch(setEmployeeRoundsPage(e.detail.pageNumber))
            }
          />
        </div>
      )}
    </NeuTable>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    loading: state.ReportReducer.loading,
    modalType: state.ModalReducer.modalType,
    roundsReportFilter: state.ReportReducer.roundsReportFilter,
    searchedEmployees: state.EmployeeReducer.searchedEmployees,
    selectedEmployee: state.UserReducer.selectedEmployee,
    totalTableCount: state.ReportReducer.totalTableCount
  };
};

export default connect(mapReduxStateToProps)(EmployeeRoundsReportTable);
