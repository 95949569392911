/**
 * External Imports
 */
import {
  FC,
  Fragment,
  MouseEvent,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  NeuAvatar,
  NeuIcon,
  NeuImage,
  NeuButton,
  NeuCardTitle,
  NeuContainer,
  NeuDivider,
  NeuOption,
  NeuCheckbox
} from '@neutron/react';
import {
  NeuInputChangeEventDetail,
  NeuTextareaChangeEventDetail
} from '@neutron/core';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import RoundingTypeDropdown from '../../customDropdowns/roundingTypeDropdown';
import { BlueChip } from '../../shared/chips/blueChip';
import { Dropdown } from '../../shared/dropdowns';
import { DeleteBtn, MarkCompleteBtn } from '../../shared/buttons';
import CategorySelect from '../reusableModalPieces/categorySelect/CategorySelect';
import EmployeeSearch from '../reusableModalPieces/employeeSearch';
import IssueSubHeader from '../reusableModalPieces/subHeader';
import PatientSearch from '../reusableModalPieces/patientSearch';
import SearchView from '../reusableModalPieces/searchView';
import { toast } from '../../../services/Toast';

import { RootState } from '../../../redux/store';
import {
  getEmployees,
  clearEmployeeSearch,
  searchEmployeesToShare
} from '../../../redux/actions/Employee.action';
import {
  setPendingIssue,
  getRound,
  clearRoundState
} from '../../../redux/actions/Round.action';
import { clearPatientSearch } from '../../../redux/actions/Report.action';
import {
  addTaskToSharingQ,
  postNewTask,
  saveTaskData,
  clearTaskData,
  saveModalState,
  clearModalState,
  resetEmployeeSharedWith,
  getSCLocations,
  getGELocations,
  verifyControlNumber,
  clearControlNumberResponse,
  clearSingleIssueFromEmail,
  getTaskDiscussions,
  postTaskComment,
  clearIssueById,
  getCategories
} from '../../../redux/actions/Task.action';
import {
  openModal,
  setModalType,
  setModalAction
} from '../../../redux/actions/Modal.action';
import {
  getStoplightById,
  clearStoplightResponse,
  clearSelectedStoplight
} from '../../../redux/actions/Stoplight.action';
import {
  setSelectedPatient,
  getPatientsToRound,
  clearPatientsToRound,
  setSelectedEmployee,
  getEmployeeNotes
} from '../../../redux/actions/User.action';
import {
  setChosenRoundTemplate,
  getTemplateMaster
} from '../../../redux/actions/Template.action';
import { setToastType } from '../../../redux/actions/Toast.action';

import { handleModal } from '../../../services/ModalPortal/utils';
import { useUserRolesRef } from '../../../services/UserRoles';
import { logAdHocClick, logTaskClick } from '../../../utils/analyticsHelpers';
import { useDebounceValue } from '../../../utils/debouncers';
import {
  abbreviatedName,
  compareObjByStringKey,
  createAnyId,
  date,
  formatPhoneNumber,
  formatRoundingType,
  text,
  toTitleCase
} from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import {
  AuthUserProps,
  Category,
  Comment,
  Employee,
  Issue,
  Patient,
  Stoplight,
  Task,
  TaskPost,
  Unit
} from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  AddIssueCard,
  CardHeader,
  ModalCardContent,
  InputHeading,
  ModalInput,
  ModalTextArea,
  BtnSpinner,
  DropDownSearchContainer,
  DropItem,
  ModalFooter,
  StyledBoldHeading,
  StyledCircleDoneIcon,
  StyledDivider,
  StyledEditHeaderCol,
  StyledEditHeaderRow,
  StyledRightAlignButton,
  TitleSpan,
  TitleIcon,
  ServiceCentralSection,
  StyledBoldHeadingCompleted,
  LabelHeading,
  LabelContentBold,
  ViewStoplightsClickableLabel
} from './AddIssueModal.styles';
import { ChipWrapper } from '../feedbackModal/FeedbackModal.styles';
import { StyledBoldLabel } from '../../shared/table/Table.styles';
import {
  CheckboxDiv,
  CheckboxLabel,
  StatusContainer
} from '../filterModal/FilterModal.styles';
/**
 * Static File Imports
 */
import serviceCentralLogoImg from '../../../assets/images/service_central_icon.png';

interface ModalProps {
  authorizedUser: AuthUserProps;
  issueData: Task;
  categories: Category[];
  modalType: string;
  sharedEmployees: any[];
  searchedEmployeesToShare: [];
  units: Unit[];
  edit?: boolean;
  isRoundStarted: boolean;
  modalAction: string;
  userSection: string;
  loading: boolean;
  attachedStoplight?: Stoplight;
  tempTaskObject?: Task;
  tempModalState?: { modal: string; redirect?: boolean };
  stoplightResponse: Stoplight;
  serviceCentralCategories: string[];
  itAndSCategories: string[];
  issueTypes: string[];
  roundingTypes: string[];
  scLocations: SCLocations[];
  geLocations: GELocations[];
  controlNumberResponse: Nullable<string>;
  isLoadingcontrolNumber: boolean;
  associatedPatient: {}[];
  associatedEmployee: Employee;
}

type Nullable<T> = T | null;

type RequiredItemTypes = {
  [key: string]: string | string[] | boolean | undefined;
};
const requiredItems: RequiredItemTypes = {
  category: '',
  description: ''
};
type SCLocations = {
  sys_id: string;
  u_coid: string;
  name: string;
};
interface GELocations extends SCLocations {
  u_ge_acct_num: string;
}

const AddIssueModal: FC<ModalProps> = ({
  authorizedUser,
  issueData,
  categories,
  modalType,
  sharedEmployees,
  searchedEmployeesToShare,
  units,
  edit,
  isRoundStarted,
  modalAction,
  userSection,
  loading,
  attachedStoplight,
  tempTaskObject,
  tempModalState,
  stoplightResponse,
  serviceCentralCategories,
  itAndSCategories,
  issueTypes,
  roundingTypes,
  scLocations,
  geLocations,
  controlNumberResponse,
  isLoadingcontrolNumber,
  associatedPatient,
  associatedEmployee
}) => {
  const dispatch = useDispatch();

  const [sharedSearchTerm, setSharedSearchTerm] = useState('');
  const [sharedPopState, setSharedPopState] = useState<boolean>(false);
  const [sharedSearchState, setSharedSearchState] = useState<boolean>(true);
  const [sharedWithDisplay, setSharedWithDisplay] = useState<Array<string>>([]);
  const [shareObject, setShareObject] = useState<{
    taskId?: string;
    userName: string;
    email: string[];
  }>({ taskId: '', userName: '', email: [] });

  const [requiredFields, setRequiredFields] = useState(requiredItems);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [view, setView] = useState<'info' | 'search'>('info');
  const [impactPatient, setImpactPatient] = useState<boolean>(false);
  const [taskObject, setTaskObject] = useState<TaskPost>(
    !edit
      ? {
          taskId: createAnyId('task', authorizedUser.facilityId),
          taskType: 'issue',
          category: tempTaskObject ? tempTaskObject.category : '',
          subCategory: tempTaskObject ? tempTaskObject.subCategory : '',
          completed: false,
          taskDate: date.now(),
          description: tempTaskObject ? tempTaskObject.description : '',
          facilityId: authorizedUser.facilityId,
          roundingType: issueTypes.includes(userSection) ? userSection : '',
          sharedWith: [],
          userId: authorizedUser.hcaid,
          userFullName: `${authorizedUser.firstName} ${authorizedUser.lastName}`,
          status: '',
          _source: 'web'
        }
      : {
          ...issueData,
          updatedBy: authorizedUser.hcaid,
          sharedWith: issueData?.sharedWith ? [...issueData.sharedWith] : []
        }
  );

  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [subCategorySearchTerm, setSubCategorySearchTerm] = useState('');
  const [subCategoryPopState, setSubCategoryPopState] =
    useState<boolean>(false);
  const [subCategorySearchState, setSubCategorySearchState] =
    useState<boolean>(false);
  const [filteredSubCategory, setFilteredSubCategory] =
    useState(itAndSCategories);
  const [selectedSCLocation, setSelectedSCLocation] = useState('');
  const [scLocationSearchTerm, setSCLocationSearchTerm] = useState('');
  const [scLocationPopState, setSCLocationPopState] = useState<boolean>(false);
  const [scLocationSearchState, setSCLocationSearchState] =
    useState<boolean>(false);
  const [filteredSCLocation, setFilteredSCLocation] = useState<SCLocations[]>(
    []
  );
  const [filteredGELocation, setFilteredGELocation] =
    useState<GELocations[]>(geLocations);
  const [haveControlNumber, setHaveControlNumber] = useState<boolean>(true);
  const [comment, setComment] = useState('');
  const [openRoundingType, setOpenRoundingType] = useState(false);
  const [openCat, setOpenCat] = useState(false);
  const [openSubcat, setOpenSubcat] = useState(false);
  const [openUnit, setOpenUnit] = useState(false);
  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { logTrackingEvent } = useAnalyticsApi();
  const {
    isCNEdRounder,
    isEnterpriseAdmin,
    isAllSupportRounder,
    isMultipleRounders,
    isNonSupportRounder,
    isSupportRounder
  } = useUserRolesRef();
  const localStorageDeepLinkPath = window.localStorage.getItem('task');
  const isCompleteTask =
    localStorageDeepLinkPath?.includes('task/complete') ||
    pathname.includes('task/complete');

  // debouncing search terms
  const dbSharedSearchTerm = useDebounceValue(sharedSearchTerm, 250);

  const isAnimated = useMemo(
    () => (!tempModalState?.redirect ? 'show-modal' : ''),
    [tempModalState]
  );

  const categoryNames = useMemo(
    () =>
      [...categories].sort(compareObjByStringKey('name')).map(cat => cat.name),
    [categories]
  );

  const subcategoryNames = useMemo(
    () =>
      [
        ...(categories.find((cat: Category) => taskObject.category === cat.name)
          ?.subCategories || [])
      ].sort(),
    [categories, taskObject.category]
  );

  const issueRedirectPath = useMemo(() => {
    switch (taskObject.roundingType) {
      case 'patient':
        return '/patients/issues';
      case 'employee':
        return '/employees/issues';
      default:
        return `/support/${taskObject.roundingType}/issues`;
    }
  }, [taskObject.roundingType]);

  const hasStoplightView = useMemo(
    () =>
      taskObject?.roundingType === 'patient' ||
      taskObject?.roundingType === 'employee',
    [taskObject?.roundingType]
  );

  const roundingTypesForAdHoc = useMemo(() => {
    let choices = [];
    if (isCNEdRounder && isNonSupportRounder) {
      choices = [...issueTypes];
      return choices;
    }
    if (isAllSupportRounder || (!isMultipleRounders && isSupportRounder)) {
      choices.push('cned');
      return choices;
    }
    if (isNonSupportRounder) {
      choices.push('employee', 'patient');
    }
    return choices;
  }, [issueTypes]);

  const setRequiredFieldValues = (taskObj: TaskPost) => {
    const {
      unitId,
      category,
      subCategory,
      description,
      employeeFirstName,
      roundingType
    } = taskObj;
    let tempRequiredValues;
    if (taskObj.roundingType === 'patient') {
      tempRequiredValues = {
        category,
        ...(taskObj.category && subcategoryNames.length > 0
          ? { subCategory }
          : {}),
        unitId,
        description,
        roundingType
      };
    } else {
      tempRequiredValues = {
        category,
        ...(taskObj.category && subcategoryNames.length > 0
          ? { subCategory }
          : {}),
        description,
        employeeFirstName,
        roundingType
      };
    }

    if (serviceCentralCategories.includes(category as string)) {
      if (
        category === 'Maintenance/Access (Patient/Visitor)' ||
        category === 'Maintenance/Access (Staff)' ||
        category === 'Building Maintenance (Public)' ||
        category === 'Building Maintenance (Staff)' ||
        category === 'Building Maintenance'
      ) {
        setRequiredFields({
          ...tempRequiredValues,
          callback: taskObject.callback || taskObject.u_best_contact_info || '',
          u_best_contact_info: taskObject.u_best_contact_info,
          u_issue_location: taskObject.u_issue_location || '',
          ec_loc: taskObject.ec_loc || ''
        });
      } else if (
        category === 'GE Biomed (ServiceCentral)' ||
        category === 'GE Biomed'
      ) {
        if (haveControlNumber === true) {
          setRequiredFields({
            ...tempRequiredValues,
            callback: taskObject.callback || '',
            u_best_contact_info: taskObject.u_best_contact_info,
            u_control_number: taskObject.u_control_number || '',
            u_ge_loc: taskObject.u_ge_loc || ''
          });
        } else {
          setRequiredFields({
            ...tempRequiredValues,
            callback: taskObject.callback || '',
            u_issue_location: taskObject.u_issue_location || '',
            u_best_contact_info: taskObject.u_best_contact_info,
            u_ge_loc: taskObject.u_ge_loc || '',
            u_reason_for_no_control_number:
              taskObject.u_reason_for_no_control_number || ''
          });
        }
      } else if (
        category === 'ITG' ||
        category === 'IT&S' ||
        category === 'ITG (ServiceCentral)' ||
        category === 'IT&S (ServiceCentral)'
      ) {
        setRequiredFields({
          ...tempRequiredValues,
          callback: taskObject.callback || '',
          u_issue_location: taskObject.u_issue_location || '',
          u_best_contact_info: taskObject.u_best_contact_info,
          taskCategory: taskObject.taskCategory || ''
        });
      }
    } else {
      setRequiredFields({ ...tempRequiredValues });
    }
  };

  const clearForm = () => {
    setTaskObject({
      taskId: '',
      taskType: 'issue',
      category: '',
      subCategory: '',
      completed: false,
      taskDate: '',
      description: '',
      facilityId: '',
      roundingType: '',
      sharedWith: [],
      stoplightId: '',
      userId: '',
      userFullName: '',
      status: '',
      _source: 'web',
      callback: ''
    });
    dispatch(clearPatientSearch());
    dispatch(clearEmployeeSearch());
  };

  const changeIssueOrigin = (type: string) => {
    setTaskObject({
      ...taskObject,
      roundingType: type === 'csc' ? 'csrn' : type,
      category: ''
    });
  };

  const handleSearching = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    setSharedSearchState(true);
    setSharedSearchTerm(target.value);
  };

  const handleInputs = (e: CustomEvent<KeyboardEvent>) => {
    const target = e.target as HTMLNeuInputElement;
    const { name } = target;
    const value = target.value as string;

    let tempFields = {};
    if (
      name === 'callback' &&
      ((value && value.length === 10) || value.length === 14)
    ) {
      tempFields = { ...tempFields, u_best_contact_info: value };
      setTaskObject({
        ...taskObject,
        ...tempFields,
        [name]: formatPhoneNumber(value)
      });
    } else if (name === 'category') {
      dispatch(clearControlNumberResponse());
      setTaskObject({
        ...taskObject,
        ...tempFields,
        subCategory: '',
        [name]: value as string
      });
    } else {
      setTaskObject({ ...taskObject, ...tempFields, [name]: value });
    }
    // if user deselects unknown control number check box, then reset unknown inputs if any
    if (name === 'u_control_number' && !haveControlNumber) {
      setHaveControlNumber(true);
      const controlTaskObject = taskObject;
      if (
        taskObject.u_reason_for_no_control_number ||
        taskObject.u_issue_location
      ) {
        delete controlTaskObject.u_reason_for_no_control_number;
        delete controlTaskObject.u_issue_location;
        setTaskObject(controlTaskObject);
      }
    }
    if (Object.keys(requiredFields).includes(name)) {
      // For validation
      setRequiredFields(
        name === 'callback' && (value.length === 10 || 14)
          ? { ...requiredFields, [name]: value, u_best_contact_info: value }
          : { ...requiredFields, [name]: value }
      );
    }
  };

  const onImpactPatient = (e: MouseEvent<HTMLNeuCheckboxElement>) => {
    const { name } = e.currentTarget;
    if (name === 'patientImpact') {
      setImpactPatient(true);
      setTaskObject({
        ...taskObject,
        patientImpact: true
      });
    } else {
      setImpactPatient(false);
      setTaskObject({
        ...taskObject,
        patientImpact: false
      });
    }
  };
  const handleDropdownChange = useCallback(
    (value: string, name: string) => {
      if (name === 'category') {
        dispatch(clearControlNumberResponse());
        setSelectedSCLocation('');
        setTaskObject({ ...taskObject, [name]: value, subCategory: '' });
      } else {
        setTaskObject({ ...taskObject, [name]: value });
      }

      // For validation
      if (Object.keys(requiredFields).includes(name)) {
        setRequiredFields({ ...requiredFields, [name]: value });
      }
    },
    [requiredFields, taskObject]
  );

  useEffect(() => {
    if (serviceCentralCategories.includes(taskObject.category as string)) {
      if (
        taskObject.category === 'GE Biomed (ServiceCentral)' ||
        taskObject.category === 'GE Biomed'
      ) {
        dispatch(getGELocations());
      } else {
        dispatch(getSCLocations());
      }
    }
  }, [taskObject.category]);

  const handleRemoveSharedWith = (emp: string) => {
    let removedEmpName: string;
    const spaces = emp.split(' ').length - 1;
    if (spaces === 2) {
      removedEmpName = emp.split(' ').slice(0, 2).join('.');
    } else {
      const splittedArray = emp.split(' ');
      removedEmpName = `${splittedArray[0] + splittedArray[1]}.${
        splittedArray[2]
      }`;
    }
    const removedEmpId = emp.split(/[\s+]+/).pop() || '';
    // Update sharedWith in task object
    const newSharedWith = taskObject.sharedWith.filter((item: string) => {
      return item.includes(removedEmpId?.toLowerCase()) === false;
    });
    setTaskObject({
      ...taskObject,
      sharedWith: newSharedWith
    });

    // update Emails for sharing
    const emailsAfterRemove = shareObject.email.filter((email: string) => {
      const existingString = email.split('@')[0];
      return existingString !== removedEmpName;
    });
    setShareObject({ ...shareObject, email: emailsAfterRemove });
  };

  const handleRemoveAttachStoplight = (
    e: MouseEvent<HTMLNeuIconElement>,
    stoplight: Stoplight
  ) => {
    e.stopPropagation();
    dispatch(setToastType({ type: 'Delete Stoplight' }));
    if (stoplight.id === taskObject.stoplightId) {
      toast(
        `Are you sure you would like to un-attach stoplight ${stoplight.friendlyId}?`,
        'info',
        null,
        400,
        true,
        null,
        () => {
          dispatch(clearStoplightResponse());
        }
      );
    } else if (taskObject.stoplightId) {
      dispatch(getStoplightById(taskObject.stoplightId));
    } else {
      dispatch(clearStoplightResponse());
    }
  };

  const unitName = useMemo(() => {
    const foundUnit: Unit[] = units.filter(
      (unit: Unit) => unit.unitId === taskObject.unitId
    );
    return foundUnit[0]?.unit;
  }, [taskObject, units]);

  const handleClickCreateStoplight = () => {
    dispatch(saveTaskData(taskObject));
    dispatch(
      saveModalState({
        modal: true,
        modalType,
        modalAction,
        redirect: true
      })
    );
    // Open stoplight modal from issue panel
    dispatch(setModalType('Add Stoplight'));
    dispatch(setModalAction(modalAction));
    dispatch(openModal());
  };

  // Search and select subCategory
  const handleSearchCategory = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    const searchInput = target.value ? target.value.trim() : '';
    setSubCategorySearchTerm(target.value);
    setSubCategorySearchState(true);
    if (searchInput) {
      const searchKey = searchInput.toUpperCase();
      const resultFacility = itAndSCategories.filter((item: string) => {
        return item.toUpperCase().includes(searchKey);
      });
      setFilteredSubCategory(resultFacility);
      setSubCategoryPopState(true);
    } else {
      setFilteredSubCategory(itAndSCategories);
      setSubCategoryPopState(true);
    }
  };

  const setSubCategory = (selectedItem: string) => {
    setSelectedSubCategory(selectedItem);
    setTaskObject({ ...taskObject, taskCategory: [selectedItem] });
    setSubCategorySearchState(false);
    setSubCategorySearchTerm('');
    setSubCategoryPopState(false);
  };

  const ControlNumberOptionChange = () => {
    if (!haveControlNumber) {
      setHaveControlNumber(true);
    } else {
      setHaveControlNumber(false);
    }
  };

  const setPatientForIssue = (patient: Patient) => {
    setTaskObject({
      ...taskObject,
      accountNum: patient.accountNumber as string,
      patientFirstName: patient.firstname as string,
      patientLastName: patient.lastname as string,
      patientFullName:
        patient.firstname && patient.lastname
          ? `${patient.firstname} ${patient.lastname}`
          : '',
      room: patient.room,
      bed: patient.bed || ''
    });
  };

  const setEmployeeToShare = (emp: Employee) => {
    const empId = emp.employeeId;
    const empExists = taskObject.sharedWith.includes(empId);
    // if the employee already exists in share, do not add again
    if (!edit && empExists) {
      setShareObject({
        taskId: taskObject.taskId,
        userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`,
        email: [...shareObject.email, emp.email]
      });
    } else if (!empExists) {
      setTaskObject({
        ...taskObject,
        sharedWith: [...taskObject.sharedWith, empId]
      });
      setSharedWithDisplay([
        ...sharedWithDisplay,
        `${emp.firstName} ${emp.lastName} ${empId}`
      ]);
      setShareObject({
        taskId: taskObject.taskId,
        userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`,
        email: [...shareObject.email, emp.email]
      });
    } else {
      // TODO future development add a toast here saying 'user already selected'
      console.log('User already selected');
    }

    setSharedSearchState(false);
    setSharedSearchTerm('');
    setSharedPopState(false);
    dispatch(clearEmployeeSearch());
  };

  const setEmployeeForIssue = (emp: Employee) => {
    setTaskObject({
      ...taskObject,
      employeeId: emp.employeeId,
      employeeFirstName: emp.firstName,
      employeeLastName: emp.lastName,
      employeeFullName:
        emp.firstName && emp.lastName ? `${emp.firstName} ${emp.lastName}` : '',
      department: emp.department,
      departmentCode: emp.departmentCode,
      departmentId: emp.departmentId
    });
    // For validation
    setRequiredFields({ ...requiredFields, employeeFirstName: emp.firstName });
  };

  // adding stoplight to issue
  const setStoplightToAttach = (stoplight: Stoplight) => {
    if (stoplight.id) {
      dispatch(getStoplightById(stoplight.id));
    }
  };

  const handleClose = () => {
    if (modalAction === 'UNIVERSAL-ADD') {
      logTrackingEvent(logAdHocClick(modalType, 'close'));
    } else {
      logTrackingEvent(logTaskClick('issues', 'close'));
    }
    // Clear temporarily stored task data
    window.localStorage.removeItem('task');
    dispatch(clearTaskData());
    dispatch(clearModalState());
    dispatch(clearStoplightResponse());
    clearForm();
    setShareObject({ taskId: '', userName: '', email: [] });
    setSharedWithDisplay([]);
    handleModal(modalContentRef);
    if (isCompleteTask) {
      navigate(issueRedirectPath);
    }
  };

  const handlePost = () => {
    if (showButton) {
      const payload = { ...taskObject };
      if (
        shareObject.taskId &&
        shareObject.userName &&
        shareObject.email.length > 0
      ) {
        dispatch(addTaskToSharingQ(shareObject));
      }
      if (attachedStoplight && Object.keys(attachedStoplight).length > 0)
        payload.stoplightId = attachedStoplight.id;
      if (edit) {
        const updatedIssue = {
          ...payload,
          facilityId: payload.facilityId
            ? payload.facilityId
            : payload?.taskId?.split(':')[1],
          updated: date.now()
        };
        delete updatedIssue.discussion;
        dispatch(postNewTask(updatedIssue));
      } else if (isRoundStarted && !edit) {
        dispatch(setPendingIssue(payload));
      } else {
        dispatch(postNewTask(payload));
      }
      clearForm();
      setShareObject({ taskId: '', userName: '', email: [] });
      setSharedWithDisplay([]);
      // Clear temporarily stored task data
      dispatch(clearTaskData());
      dispatch(clearModalState());
      dispatch(clearStoplightResponse());
      handleModal(modalContentRef);
      if (isCompleteTask) {
        navigate(issueRedirectPath);
      }
    }
  };

  const handleRedirect = () => {
    if (taskObject.roundingType === 'patient') {
      if (associatedPatient && associatedPatient.length === 1) {
        dispatch(clearPatientsToRound());
        handleModal(modalContentRef);
        if (taskObject?.accountNum?.substring(0, 2) === 'UL') {
          navigate('/patients/profile', {
            state: { isUnlistedPatient: true },
            replace: true
          });
        } else {
          navigate('/patients/profile', {
            state: { isUnlistedPatient: false },
            replace: true
          });
        }
      }
    }
    if (
      taskObject.roundingType === 'employee' ||
      taskObject.roundingType === 'csc' ||
      taskObject.roundingType === 'cned'
    ) {
      getEmployeeNotes(issueData.employeeId);
      handleModal(modalContentRef);
      let profileRedirectPath = '';
      switch (taskObject.roundingType) {
        case 'employee':
          profileRedirectPath = '/employees/profile';
          break;
        default:
          profileRedirectPath = `/support/profile/${taskObject.roundingType}`;
          break;
      }
      navigate(profileRedirectPath);
    }
  };
  /* Code block for navigating to round from issue modal */

  const handleRemoveChip = (type: string) => {
    if (type === 'patient') {
      setTaskObject({
        ...taskObject,
        accountNum: '',
        patientFirstName: '',
        patientLastName: '',
        patientFullName: '',
        room: '',
        bed: ''
      });
    } else {
      setTaskObject({
        ...taskObject,
        employeeId: '',
        employeeFirstName: '',
        employeeLastName: '',
        employeeFullName: '',
        department: '',
        departmentCode: '',
        departmentId: ''
      });
    }
  };

  const handlePostComment = useCallback(() => {
    if (comment) {
      dispatch(
        postTaskComment({
          task: taskObject,
          comment: {
            id: issueData?.taskId,
            comment: {
              body: comment,
              authorId: authorizedUser.hcaid,
              authorFullName: `${authorizedUser.firstName} ${authorizedUser.lastName}`
            }
          }
        })
      );
      setComment('');
    }
  }, [comment, issueData, authorizedUser]);

  // Search and select Service Central categories
  const handleSearchSCLocation = (
    e: CustomEvent<NeuInputChangeEventDetail>
  ) => {
    const target = e.target as HTMLInputElement;
    const searchInput = target.value ? target.value.trim() : '';
    setSCLocationSearchTerm(target.value);
    setSCLocationPopState(true);
    if (searchInput) {
      const searchKey = searchInput.toUpperCase();
      if (
        taskObject.category === 'GE Biomed (ServiceCentral)' ||
        taskObject.category === 'GE Biomed'
      ) {
        const resultLocations = geLocations.filter((item: GELocations) => {
          return item.name.toUpperCase().includes(searchKey);
        });
        setFilteredGELocation(resultLocations);
      } else {
        const resultLocations = scLocations.filter((item: SCLocations) => {
          return item.name.toUpperCase().includes(searchKey);
        });
        setFilteredSCLocation(resultLocations);
      }
      setSCLocationPopState(true);
    } else {
      setFilteredSCLocation(scLocations);
      setSCLocationPopState(true);
    }
  };

  const setSCLocation = (selectedItem: SCLocations) => {
    if (
      taskObject.category === 'GE Biomed (ServiceCentral)' ||
      taskObject.category === 'GE Biomed'
    ) {
      setTaskObject({ ...taskObject, u_ge_loc: selectedItem.sys_id });
    } else {
      setTaskObject({ ...taskObject, ec_loc: selectedItem.sys_id });
    }
    setSelectedSCLocation(selectedItem.name);

    setSCLocationSearchState(false);
    setSCLocationSearchTerm('');
    setSCLocationPopState(false);
  };

  // modifying required fields based on rounding type
  useEffect(() => {
    const copyTaskObject = { ...taskObject };
    if (!serviceCentralCategories.includes(taskObject.category as string)) {
      delete copyTaskObject.callback;
      delete copyTaskObject.u_best_contact_info;
      delete copyTaskObject.u_issue_location;
      delete copyTaskObject.u_control_number;
      delete copyTaskObject.ec_loc;
      delete copyTaskObject.u_ge_loc;
      delete copyTaskObject.taskCategory;
      setTaskObject(copyTaskObject);
    }
    setRequiredFieldValues(copyTaskObject);
  }, [
    taskObject.roundingType,
    taskObject.category,
    taskObject.taskCategory,
    taskObject.ec_loc,
    taskObject.u_ge_loc,
    taskObject.sys_id,
    tempTaskObject,
    haveControlNumber,
    impactPatient
  ]);

  // getting issue types to populate dropdowns, setting issue origin based on location
  useEffect(() => {
    if (
      edit &&
      !serviceCentralCategories.includes(taskObject.category as string)
    )
      dispatch(getTaskDiscussions({ id: issueData?.taskId }));
    return () => {
      // clear out on dismount
      dispatch(clearIssueById());
      setSharedWithDisplay([]);
      dispatch(resetEmployeeSharedWith());
      if (stoplightResponse && stoplightResponse.id) {
        dispatch(clearModalState());
        dispatch(clearStoplightResponse());
        dispatch(clearTaskData());
      }
      if (!taskObject.stoplightId) {
        dispatch(clearStoplightResponse());
      }
      dispatch(clearSingleIssueFromEmail());
    };
  }, []);

  useEffect(() => {
    if (roundingTypes.includes(taskObject.roundingType)) {
      let categoryFor;
      switch (taskObject.roundingType) {
        case 'patient':
          categoryFor = 'patient';
          break;
        default:
          categoryFor = 'employee';
          break;
      }
      dispatch(
        getCategories({
          categoryFor,
          categoryType: 'issue'
        })
      );
    }
  }, [taskObject.roundingType]);

  // shared with search
  useEffect(() => {
    if (sharedSearchTerm.length > 2) {
      dispatch(searchEmployeesToShare(dbSharedSearchTerm));
    }
  }, [dbSharedSearchTerm]);

  useEffect(() => {
    if (searchedEmployeesToShare.length > 0 && sharedSearchTerm.length > 0) {
      setSharedPopState(true);
    }
  }, [searchedEmployeesToShare]);

  // Validate fields on change and disable button
  useEffect(() => {
    let count = 0;
    Object.keys(requiredFields).forEach(item => {
      const tempVar = requiredFields[item];
      if (!tempVar || (Array.isArray(tempVar) && tempVar.length === 0)) {
        count += 1;
      }
      if (count === 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, [requiredFields]);

  useEffect(() => {
    const tempTaskObj = {
      ...taskObject,
      ...issueData,
      ...tempTaskObject
    };
    if (issueData && modalType === 'Add Patient Issue On Rounds') {
      const patientTaskObj: Task = {
        ...tempTaskObj,
        roundingType: userSection,
        taskId: createAnyId('task', authorizedUser.facilityId),
        taskDate: date.now(),
        unitId: issueData.unitId,
        facilityId: authorizedUser.facilityId,
        updatedBy: authorizedUser.hcaid,
        userId: authorizedUser.hcaid,
        accountNum: issueData?.accountNum,
        patientFirstName: issueData.firstName as string,
        patientLastName: issueData.lastName as string,
        patientFullName:
          issueData.firstName && issueData.lastName
            ? `${issueData.firstName} ${issueData.lastName}`
            : '',
        room: issueData.room,
        bed: issueData.bed as string
      };
      delete patientTaskObj.firstName;
      delete patientTaskObj.lastName;
      setTaskObject(patientTaskObj);
      if (tempTaskObject?.sharedWith) {
        dispatch(getEmployees([...issueData.sharedWith]));
      }
    } else if (
      issueData &&
      (modalType === 'Add Employee Issue On Rounds' ||
        modalType === 'Add Support Issue On Rounds')
    ) {
      const employeeTaskObj: Task = {
        ...tempTaskObj,
        roundingType: userSection,
        taskId: createAnyId('task', authorizedUser.facilityId),
        taskDate: date.now(),
        facilityId: authorizedUser.facilityId,
        updatedBy: authorizedUser.hcaid,
        userId: authorizedUser.hcaid,
        employeeFirstName: issueData.firstName as string,
        employeeLastName: issueData.lastName as string,
        employeeFullName:
          issueData.firstName && issueData.lastName
            ? `${issueData.firstName} ${issueData.lastName}`
            : '',
        employeeId: issueData.hcaid
      };
      delete employeeTaskObj.firstName;
      delete employeeTaskObj.lastName;
      setTaskObject(employeeTaskObj);
      if (tempTaskObject?.sharedWith && issueData?.sharedWith) {
        dispatch(getEmployees([...issueData.sharedWith]));
      }
    } else {
      setTaskObject({ ...tempTaskObj });
      if (tempTaskObject?.sharedWith && issueData?.sharedWith) {
        dispatch(getEmployees([...issueData.sharedWith]));
      }
    }
    if (edit && issueData) {
      setTaskObject({
        ...tempTaskObj,
        category: tempTaskObj.category || tempTaskObj.taskSubtype,
        // THIS IS A BUG
        patientFullName:
          issueData.patientFirstName && issueData.patientLastName
            ? `${issueData.patientFirstName} ${issueData.patientLastName}`
            : '',
        sharedWith: issueData.sharedWith ? [...issueData.sharedWith] : []
      });
      if (issueData.sharedWith) {
        dispatch(getEmployees([...issueData.sharedWith]));
      }
      // Get attached stoplight details
      if (taskObject.stoplightId) {
        dispatch(getStoplightById(taskObject.stoplightId));
      }
      // Set default selection in edit mode
      if (!issueData.u_control_number) {
        setHaveControlNumber(false);
      } else {
        setHaveControlNumber(true);
      }
    }
    if (subcategoryNames.length > 0) {
      if (userSection === 'patient') {
        setRequiredFields({
          category: tempTaskObj.category || taskObject.category || '',
          subCategory: tempTaskObj.subCategory || taskObject.subCategory || '',
          description: tempTaskObj.description || taskObject.description || '',
          unitId: tempTaskObj.unitId || taskObject.unitId || ''
        });
      } else {
        setRequiredFields({
          category: tempTaskObj.category || taskObject.category || '',
          subCategory: tempTaskObj.subCategory || taskObject.subCategory || '',
          description: tempTaskObj.description || taskObject.description || ''
        });
      }
    } else if (userSection === 'patient') {
      setRequiredFields({
        category: tempTaskObj.category || taskObject.category || '',
        description: tempTaskObj.description || taskObject.description || '',
        unitId: tempTaskObj.unitId
      });
    } else {
      setRequiredFields({
        category: tempTaskObj.category || taskObject.category || '',
        description: tempTaskObj.description || taskObject.description || ''
      });
    }
  }, [edit, issueData, modalType, tempTaskObject]);

  useEffect(() => {
    if (!edit && tempModalState?.modal) {
      sharedEmployees.forEach((emp: Employee) => setEmployeeToShare(emp));
    }
    if ((edit && issueData) || tempModalState?.modal) {
      const alreadySharedEmployees: string[] = sharedEmployees.map(
        (emp: Employee) => {
          const empId = emp.employeeId?.toUpperCase();
          return `${emp.firstName} ${emp.lastName} ${empId}`;
        }
      );
      setSharedWithDisplay([...alreadySharedEmployees]);
    }
  }, [sharedEmployees]);

  useEffect(() => {
    if (controlNumberResponse) {
      handlePost();
    }
  }, [controlNumberResponse]);

  // Auto attach created stoplight with issue
  useEffect(() => {
    if (stoplightResponse && stoplightResponse.id) {
      dispatch(clearSelectedStoplight());
      setStoplightToAttach(stoplightResponse);
    }
  }, [stoplightResponse]);

  // Search and select subCategory - END

  useEffect(() => {
    setFilteredSCLocation(scLocations);
    setFilteredGELocation(geLocations);
    if (issueData && (issueData.ec_loc || issueData.u_ge_loc)) {
      const selectedSCLoc =
        scLocations.length &&
        scLocations.filter(item => item.sys_id === issueData.ec_loc);
      if (selectedSCLoc && selectedSCLoc.length === 1) {
        setSelectedSCLocation(selectedSCLoc[0]?.name);
      }
      const selectedGELoc =
        geLocations.length &&
        geLocations.filter(item => item.sys_id === issueData.u_ge_loc);
      if (selectedGELoc && selectedGELoc.length === 1) {
        setSelectedSCLocation(selectedGELoc[0]?.name);
      }
    }
  }, [issueData, scLocations, geLocations]);

  // Search and select Service Central categories - END
  /* Code block for navigating to round from issue modal */
  useEffect(() => {
    if (edit && taskObject) {
      if (
        userSection === 'patient' &&
        taskObject?.facilityId &&
        taskObject?.accountNum
      ) {
        dispatch(
          getPatientsToRound({
            facilityId: taskObject.facilityId,
            accountNum: taskObject.accountNum
          })
        );
      }

      if (
        (userSection === 'employee' ||
          userSection === 'csc' ||
          userSection === 'cned') &&
        taskObject?.employeeId
      ) {
        if (associatedEmployee?.hcaid !== taskObject.employeeId.toLowerCase()) {
          dispatch(
            setSelectedEmployee({
              hcaid: taskObject.employeeId.toLowerCase(),
              firstName: taskObject.employeeFirstName,
              lastName: taskObject.employeeLastName,
              email: ''
            })
          );
        }
      }
      if (taskObject?.roundId)
        dispatch(
          getRound({
            roundId: taskObject.roundId,
            fromHistorydrawer: false
          })
        );
      dispatch(getTemplateMaster({ condensed: true, archived: false }));
    } else if (
      !issueData?.roundId &&
      !(modalAction === 'UNIVERSAL-ADD' && modalType === 'Add Issue') &&
      !modalType.includes('Issue On Rounds')
    ) {
      dispatch(setChosenRoundTemplate({}));
      dispatch(clearRoundState());
    }
  }, []);

  useEffect(() => {
    if (edit && associatedPatient && associatedPatient.length === 1) {
      dispatch(setSelectedPatient(associatedPatient[0]));
    }
  }, [associatedPatient]);

  return (
    <AddIssueCard
      id="Modal-Add-Issues"
      className={isAnimated}
      type="submit"
      height="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        {!edit && (
          <NeuCardTitle>
            <TitleSpan>
              <TitleIcon large>flag</TitleIcon>
              Add Issue
            </TitleSpan>
          </NeuCardTitle>
        )}
        {edit && !taskObject.completed && (
          <MarkCompleteBtn edit taskId={taskObject.taskId} taskType="issue" />
        )}
        {edit && taskObject.completed && (
          <StyledBoldHeading className="mt-0" autoWidth>
            {taskObject.roundingType === 'patient' ? 'Patient' : 'Employee'} |{' '}
            {taskObject.category}
          </StyledBoldHeading>
        )}
        <NeuButton fill="flat" onClick={handleClose}>
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      {hasStoplightView && (
        <IssueSubHeader
          completed={taskObject.completed}
          items={attachedStoplight?.id}
          type="issue"
          view={view}
          setView={setView}
        />
      )}
      {view === 'info' && (
        <ModalCardContent>
          {edit && (
            <>
              <LabelHeading>Issue ID</LabelHeading>
              <LabelContentBold>
                {taskObject.friendlyId ? taskObject.friendlyId : 'Unknown'}
              </LabelContentBold>
            </>
          )}
          {!(
            modalType === 'Add Patient Issue On Rounds' ||
            modalType === 'Add Employee Issue On Rounds' ||
            modalType === 'Add Support Issue On Rounds'
          ) &&
          (!edit ||
            (!taskObject.completed &&
              (isEnterpriseAdmin ||
                (taskObject.taskDate &&
                  authorizedUser.hcaid === taskObject.userId &&
                  !date.isOverOneDayOld(taskObject.taskDate))))) ? (
            <div>
              {modalAction === 'UNIVERSAL-ADD' && (
                <>
                  <InputHeading>Issue Origin</InputHeading>
                  <RoundingTypeDropdown
                    name="issue-rounding-type"
                    open={openRoundingType}
                    options={roundingTypesForAdHoc}
                    placeholder={
                      taskObject?.roundingType &&
                      roundingTypesForAdHoc.includes(taskObject?.roundingType)
                        ? formatRoundingType(taskObject?.roundingType)
                        : 'Select Origin'
                    }
                    selectedRoundingType={taskObject?.roundingType}
                    setSelectedRoundingType={changeIssueOrigin}
                    setOpen={setOpenRoundingType}
                  />
                </>
              )}
              <InputHeading>
                {taskObject.roundingType === 'patient'
                  ? 'Patient (Optional)'
                  : 'Employee'}
              </InputHeading>
              {taskObject.roundingType === 'patient' ? (
                <PatientSearch
                  propObject={taskObject}
                  chipVal={
                    taskObject.patientFullName
                      ? abbreviatedName(
                          taskObject.patientFirstName,
                          taskObject.patientLastName
                        )
                      : ''
                  }
                  setPatientSelection={(patient: Patient) =>
                    setPatientForIssue(patient)
                  }
                  handleRemovePatient={handleRemoveChip}
                  showLabel={false}
                />
              ) : (
                <EmployeeSearch
                  val={taskObject}
                  chipVal={
                    taskObject.employeeFullName
                      ? text.lastFirst(
                          taskObject.employeeFullName.toString().trim()
                        )
                      : taskObject.employeeFirstName
                      ? `${toTitleCase(
                          taskObject.employeeFirstName
                        )} ${toTitleCase(taskObject.employeeLastName)}`
                      : ''
                  }
                  setEmployeeSelection={(emp: Employee) =>
                    setEmployeeForIssue(emp)
                  }
                  handleRemoveEmployee={handleRemoveChip}
                  showLabel={false}
                />
              )}
            </div>
          ) : (
            <div>
              <StyledEditHeaderRow className="px-0">
                <InputHeading>
                  {userSection === 'patient' ? 'Patient' : 'Employee'}
                </InputHeading>
                {userSection === 'patient' ? (
                  <InputHeading>Room</InputHeading>
                ) : (
                  ''
                )}
              </StyledEditHeaderRow>

              <StyledEditHeaderRow className="px-0">
                <StyledBoldHeading
                  className="mt-1"
                  color={
                    taskObject &&
                    ((associatedPatient.length === 1 &&
                      taskObject.patientFullName) ||
                      taskObject.employeeId)
                      ? '#206DA3'
                      : '#191919'
                  }
                  cursorType={
                    !!(
                      taskObject &&
                      ((associatedPatient.length === 1 &&
                        taskObject.patientFullName) ||
                        taskObject.employeeId)
                    )
                  }
                  onClick={() => {
                    handleRedirect();
                  }}
                >
                  {taskObject.roundingType === 'patient'
                    ? text.lastFirst(taskObject.patientFullName) || 'Unknown'
                    : text.lastFirst(taskObject.employeeFullName) || 'Unknown'}
                </StyledBoldHeading>
                {userSection === 'patient' && (
                  <StyledBoldHeading className="mt-1">
                    {taskObject.room
                      ? `${taskObject.room}${taskObject.bed || ''}`
                      : 'N/A'}
                  </StyledBoldHeading>
                )}
              </StyledEditHeaderRow>
            </div>
          )}
          {edit && issueData?.userFullName && (
            <>
              <StyledEditHeaderRow className="px-0 pb-0">
                <InputHeading>Creator</InputHeading>
              </StyledEditHeaderRow>
              <StyledEditHeaderRow className="px-0 pb-1 pt-0">
                <StyledBoldHeading className="mt-2">
                  {issueData?.userFullName}
                </StyledBoldHeading>
              </StyledEditHeaderRow>
            </>
          )}
          {taskObject.roundingType === 'patient' && (
            <>
              <InputHeading className="mb-2">Unit</InputHeading>
              {!edit ||
              (!taskObject.completed &&
                (isEnterpriseAdmin ||
                  (authorizedUser.hcaid === taskObject.userId &&
                    !taskObject.completed &&
                    taskObject.taskDate &&
                    !date.isOverOneDayOld(taskObject.taskDate)))) ? (
                <Dropdown
                  classes="pl-0 pr-2"
                  name="unitId"
                  open={openUnit}
                  placeholder={
                    units.find(
                      (unit: Unit) => unit.unitId === taskObject.unitId
                    )?.unit || 'Select a Unit'
                  }
                  width="97%"
                  setOpen={setOpenUnit}
                >
                  {units.map((unit: Unit) => (
                    <NeuOption
                      id={`Modal-Unit-Options-${unit.unitId}`}
                      className={`dropdown-hover-item${
                        taskObject.unitId === unit.unitId ? ' selected' : ''
                      }`}
                      value={unit.unitId}
                      key={unit.unitId}
                      onClick={() =>
                        handleDropdownChange(unit.unitId, 'unitId')
                      }
                    >
                      {unit.unit}
                    </NeuOption>
                  ))}
                </Dropdown>
              ) : (
                <StyledBoldLabel className="mt-4">{unitName}</StyledBoldLabel>
              )}
            </>
          )}
          {edit &&
            !isEnterpriseAdmin &&
            !taskObject.completed &&
            taskObject.taskDate &&
            (date.isOverOneDayOld(taskObject.taskDate) ||
              !(authorizedUser.hcaid === taskObject.userId)) && (
              <>
                <InputHeading className="mb-2">Issue Category</InputHeading>
                <StyledBoldLabel className="d-flex align-items-center">
                  {serviceCentralCategories.includes(
                    taskObject.category as string
                  ) && (
                    <NeuImage
                      width="25px"
                      alt="service central logo"
                      src={serviceCentralLogoImg}
                    />
                  )}
                  {taskObject.category}
                </StyledBoldLabel>
              </>
            )}
          {((authorizedUser.hcaid === taskObject.userId &&
            !taskObject.completed &&
            taskObject.taskDate &&
            !date.isOverOneDayOld(taskObject.taskDate)) ||
            (isEnterpriseAdmin && !taskObject.completed)) && (
            <CategorySelect
              disabled={!roundingTypes.includes(taskObject.roundingType)}
              heading="Issue Category"
              categoryName="category"
              categories={categoryNames}
              categoryValue={taskObject.category || ''}
              open={openCat}
              placeholder="Select a Category"
              handleInputs={handleDropdownChange}
              setOpen={setOpenCat}
            />
          )}
          {edit &&
            taskObject.subCategory &&
            ((!isEnterpriseAdmin &&
              taskObject.taskDate &&
              (date.isOverOneDayOld(taskObject.taskDate) ||
                !(authorizedUser.hcaid === taskObject.userId))) ||
              taskObject.completed) && (
              <>
                <InputHeading className="mb-2">Issue Subcategory</InputHeading>
                <StyledBoldLabel>{taskObject.subCategory}</StyledBoldLabel>
              </>
            )}
          {((authorizedUser.hcaid === taskObject.userId &&
            !taskObject.completed &&
            taskObject.taskDate &&
            !date.isOverOneDayOld(taskObject.taskDate)) ||
            (isEnterpriseAdmin && !taskObject.completed)) && (
            <CategorySelect
              disabled={!taskObject.category || subcategoryNames.length === 0}
              heading="Issue Subcategory"
              categoryName="subCategory"
              categories={subcategoryNames}
              categoryValue={taskObject.subCategory || ''}
              open={openSubcat}
              placeholder={
                taskObject.category && subcategoryNames.length === 0
                  ? 'No subcategories'
                  : 'Select a Subcategory'
              }
              handleInputs={(e: any) => handleDropdownChange(e, 'subCategory')}
              setOpen={setOpenSubcat}
            />
          )}
          {serviceCentralCategories.includes(taskObject.category as string) && (
            <ServiceCentralSection>
              {(taskObject.category === 'ITG' ||
                taskObject.category === 'IT&S' ||
                taskObject.category === 'ITG (ServiceCentral)' ||
                taskObject.category === 'IT&S (ServiceCentral)') && (
                <>
                  <InputHeading>Category</InputHeading>
                  <div
                    style={{ position: 'relative' }}
                    onBlur={() => setSubCategoryPopState(false)}
                  >
                    <ModalInput
                      type="search"
                      name="taskCategory"
                      autocomplete="off"
                      placeholder="Choose issue type"
                      inputmode="search"
                      enterkeyhint="search"
                      autocorrect="off"
                      spellcheck
                      value={
                        subCategorySearchState
                          ? subCategorySearchTerm
                          : selectedSubCategory ||
                            (taskObject.taskCategory &&
                              taskObject.taskCategory[0])
                      }
                      onNeuChange={
                        subCategorySearchState ? handleSearchCategory : () => {}
                      }
                      onNeuFocus={() => {
                        setSubCategorySearchState(true);
                        setSubCategoryPopState(true);
                      }}
                      onNeuBlur={() => {
                        setSubCategorySearchState(false);
                        setSubCategorySearchTerm('');
                        setSubCategoryPopState(false);
                      }}
                    />
                    <DropDownSearchContainer showPopover={subCategoryPopState}>
                      {filteredSubCategory &&
                        filteredSubCategory.map((item: string) => (
                          <DropItem
                            onMouseDown={() => setSubCategory(item)}
                            key={item}
                            button
                          >
                            {item}
                          </DropItem>
                        ))}
                    </DropDownSearchContainer>
                  </div>
                  <InputHeading>Issue Location</InputHeading>
                  <ModalInput
                    type="text"
                    name="u_issue_location"
                    autocomplete="off"
                    placeholder="Please note the issue's physical location."
                    inputmode="text"
                    enterkeyhint="next"
                    autocorrect="off"
                    required
                    spellcheck
                    value={taskObject.u_issue_location}
                    onNeuInput={handleInputs}
                  />
                </>
              )}
              {(taskObject.category ===
                'Maintenance/Access (Patient/Visitor)' ||
                taskObject.category === 'Maintenance/Access (Staff)' ||
                taskObject.category === 'Building Maintenance (Public)' ||
                taskObject.category === 'Building Maintenance (Staff)' ||
                taskObject.category === 'Building Maintenance' ||
                taskObject.category === 'GE Biomed (ServiceCentral)' ||
                taskObject.category === 'GE Biomed') && (
                <>
                  <InputHeading>Facility</InputHeading>
                  <div
                    style={{ position: 'relative' }}
                    onBlur={() => setSCLocationPopState(false)}
                  >
                    <ModalInput
                      type="search"
                      name="ec_loc"
                      autocomplete="off"
                      placeholder="Select a Location"
                      inputmode="search"
                      enterkeyhint="search"
                      autocorrect="off"
                      required
                      spellcheck
                      value={
                        scLocationSearchState
                          ? scLocationSearchTerm
                          : selectedSCLocation
                      }
                      onNeuChange={
                        scLocationSearchState
                          ? handleSearchSCLocation
                          : () => {}
                      }
                      onNeuFocus={() => {
                        setSCLocationSearchState(true);
                        setSCLocationPopState(true);
                      }}
                      onNeuBlur={() => {
                        setSCLocationSearchState(false);
                        setSCLocationSearchTerm('');
                        setSCLocationPopState(false);
                      }}
                    />
                    <DropDownSearchContainer showPopover={scLocationPopState}>
                      {taskObject.category === 'GE Biomed (ServiceCentral)' ||
                      taskObject.category === 'GE Biomed'
                        ? filteredGELocation &&
                          filteredGELocation.map((item: GELocations) => (
                            <DropItem
                              onMouseDown={() => setSCLocation(item)}
                              key={item.sys_id}
                              button
                            >
                              {item.name}
                            </DropItem>
                          ))
                        : filteredSCLocation &&
                          filteredSCLocation.map((item: SCLocations) => (
                            <DropItem
                              onMouseDown={() => setSCLocation(item)}
                              key={item.sys_id}
                              button
                            >
                              {item.name}
                            </DropItem>
                          ))}
                    </DropDownSearchContainer>
                  </div>

                  {(taskObject.category === 'GE Biomed (ServiceCentral)' ||
                    taskObject.category === 'GE Biomed') && (
                    <>
                      <InputHeading>Control Number</InputHeading>
                      <ModalInput
                        type="text"
                        name="u_control_number"
                        autocomplete="off"
                        placeholder="Enter control number"
                        inputmode="text"
                        enterkeyhint="next"
                        autocorrect="off"
                        onNeuInput={handleInputs}
                        value={taskObject.u_control_number}
                      />
                      {taskObject.u_control_number ? (
                        ''
                      ) : (
                        <StatusContainer id="Add-Issue-Modal-Checkbox">
                          <CheckboxDiv>
                            <NeuCheckbox
                              id="Unknown-Control-Checkbox"
                              name="unknownControlNumber"
                              color="primary"
                              onClick={ControlNumberOptionChange}
                            />
                            <CheckboxLabel>
                              Unknown Control Number
                            </CheckboxLabel>
                          </CheckboxDiv>
                        </StatusContainer>
                      )}

                      {!haveControlNumber && (
                        <>
                          <InputHeading>
                            Provide the reason for the missing control number
                          </InputHeading>
                          <ModalInput
                            type="text"
                            name="u_reason_for_no_control_number"
                            autocomplete="off"
                            placeholder="Enter a reason for no control number"
                            inputmode="text"
                            enterkeyhint="next"
                            autocorrect="off"
                            onNeuInput={handleInputs}
                            value={taskObject.u_reason_for_no_control_number}
                          />
                          <InputHeading>Issue Location</InputHeading>
                          <ModalInput
                            type="text"
                            name="u_issue_location"
                            autocomplete="off"
                            placeholder="Please note the issue's physical location."
                            inputmode="text"
                            enterkeyhint="next"
                            autocorrect="off"
                            required
                            spellcheck
                            value={taskObject.u_issue_location}
                            onNeuInput={handleInputs}
                          />
                        </>
                      )}
                    </>
                  )}

                  {taskObject.category.includes('Maintenance') && (
                    <>
                      <InputHeading>Issue Location</InputHeading>
                      <ModalInput
                        type="text"
                        name="u_issue_location"
                        autocomplete="off"
                        placeholder="Please note the issue's physical location."
                        inputmode="text"
                        enterkeyhint="next"
                        autocorrect="off"
                        required
                        spellcheck
                        value={taskObject.u_issue_location}
                        onNeuInput={handleInputs}
                      />
                    </>
                  )}
                  {taskObject.category === 'GE Biomed' && (
                    <>
                      <InputHeading>Does this impact a patient?</InputHeading>
                      <StatusContainer id="Add-Issue-Modal-Checkbox">
                        <CheckboxDiv>
                          <NeuCheckbox
                            id="Impact-Patient-Checkbox"
                            name="patientImpact"
                            color="primary"
                            checked={impactPatient}
                            onClick={onImpactPatient}
                          />
                          <CheckboxLabel>Yes </CheckboxLabel>
                        </CheckboxDiv>
                        <CheckboxDiv>
                          <NeuCheckbox
                            id="Impact-Patient-Checkbox"
                            name="noPatientImpact"
                            color="primary"
                            checked={!impactPatient}
                            onClick={onImpactPatient}
                          />
                          <CheckboxLabel>No</CheckboxLabel>
                        </CheckboxDiv>
                      </StatusContainer>
                    </>
                  )}
                  {impactPatient && (
                    <StyledBoldLabel>
                      Please enter a patient event into your facility&apos;s
                      event reporting system, as well.
                    </StyledBoldLabel>
                  )}
                </>
              )}

              <InputHeading>
                Callback Number (10 digits, no dashes)
              </InputHeading>
              <ModalInput
                type="tel"
                pattern="tel"
                name="callback"
                autocomplete="off"
                placeholder="Please enter number"
                inputmode="tel"
                enterkeyhint="next"
                autocorrect="off"
                required
                value={taskObject.callback}
                onNeuInput={handleInputs}
              />
            </ServiceCentralSection>
          )}
          <InputHeading>Details</InputHeading>
          {!edit ||
          (!taskObject.completed &&
            (isEnterpriseAdmin ||
              (authorizedUser.hcaid === taskObject.userId &&
                !taskObject.completed &&
                taskObject.taskDate &&
                !date.isOverOneDayOld(taskObject.taskDate)))) ? (
            <ModalTextArea
              placeholder="Do not include PHI"
              wrap="hard"
              inputmode="text"
              enterkeyhint="next"
              name="description"
              required
              spellcheck
              value={taskObject.description}
              onNeuInput={handleInputs}
            />
          ) : (
            <StyledBoldLabel className="d-inline-block mt-2">
              {taskObject.description}
            </StyledBoldLabel>
          )}
          {!(taskObject.completed && !taskObject.sharedWith.length) && (
            <InputHeading>
              Share With{' '}
              {!(taskObject.completed && !taskObject.sharedWith.length) &&
                '(Optional)'}
            </InputHeading>
          )}
          <div onBlur={() => setSharedPopState(false)}>
            {sharedWithDisplay.length > 0 &&
              sharedWithDisplay.map(emp => {
                return (
                  <ChipWrapper
                    key={emp}
                    onClick={() => handleRemoveSharedWith(emp)}
                  >
                    <BlueChip name={emp} />
                  </ChipWrapper>
                );
              })}
            {!taskObject.completed && (
              <div style={{ position: 'relative' }}>
                <ModalInput
                  type="search"
                  name="sharedWith"
                  autocomplete="off"
                  placeholder="Start typing name or 3-4 ID"
                  inputmode="search"
                  enterkeyhint="search"
                  autocorrect="off"
                  value={sharedSearchState ? sharedSearchTerm : null}
                  onNeuChange={sharedSearchState ? handleSearching : () => {}}
                  onNeuFocus={() => setSharedSearchState(true)}
                />
                <DropDownSearchContainer showPopover={sharedPopState}>
                  {searchedEmployeesToShare.map((emp: Employee) => (
                    <div key={emp.employeeId}>
                      <DropItem
                        onMouseDown={() => setEmployeeToShare(emp)}
                        button
                      >
                        <p>
                          {emp.firstName.toUpperCase()}{' '}
                          {emp.lastName.toUpperCase()}
                        </p>
                        <p style={{ fontSize: '14px' }}>{emp.employeeId}</p>
                        <p style={{ fontSize: '14px' }}>{emp.title}</p>
                      </DropItem>
                      {searchedEmployeesToShare.length > 1 && <NeuDivider />}
                    </div>
                  ))}
                </DropDownSearchContainer>
              </div>
            )}
          </div>
          {hasStoplightView && (
            <ViewStoplightsClickableLabel
              className="mt-4 mb-3"
              onClick={() => setView('search')}
            >
              {attachedStoplight?.id ||
              (taskObject.completed && !attachedStoplight?.id)
                ? 'View Attached Stoplight'
                : 'Attach to Stoplight'}
            </ViewStoplightsClickableLabel>
          )}
          {/* <SearchIcons
              color={
                item.id === taskObject.stoplightId ? 'green-60' : 'primary'
              }
            >
              {item.id === taskObject.stoplightId
                ? 'check'
                : 'add_circle_outline'}
            </SearchIcons> */}
          {!taskObject.completed &&
            !attachedStoplight?.id &&
            hasStoplightView && (
              <ViewStoplightsClickableLabel
                className="mt-3 mb-4"
                onClick={handleClickCreateStoplight}
              >
                Create New Stoplight
              </ViewStoplightsClickableLabel>
            )}

          {edit && issueData?.discussion && issueData.discussion.comments && (
            <>
              {issueData.discussion.comments.length > 0 && (
                <StyledDivider className="mb-3 mt-4 mr-2" color="gray-30" />
              )}
              {issueData.discussion.comments!.map((c: Comment, i: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={i}>
                  <StyledEditHeaderRow className="my-3">
                    <NeuContainer className="px-1 pt-2">
                      <NeuAvatar
                        color="primary"
                        initials={text.getInitials(c.authorFullName)}
                      />
                    </NeuContainer>
                    <NeuContainer>
                      <StyledEditHeaderRow className="pl-0">
                        <StyledBoldHeading
                          className="mt-0"
                          color="primary"
                          autoWidth
                        >
                          {c.authorFullName}
                        </StyledBoldHeading>
                        <InputHeading
                          className="mt-0 ml-2"
                          autoWidth
                          style={{ fontSize: '0.75rem' }}
                        >
                          {c.date && date.dayOfYr(c.date)}
                        </InputHeading>
                      </StyledEditHeaderRow>
                      <StyledEditHeaderRow className="pl-0">
                        <InputHeading className="mt-0">{c.body}</InputHeading>
                      </StyledEditHeaderRow>
                    </NeuContainer>
                  </StyledEditHeaderRow>
                </Fragment>
              ))}
            </>
          )}
          {edit && !issueData?.completed && (
            <>
              <StyledDivider className="mb-3 mt-2 mr-2" color="gray-30" />
              <InputHeading className="mx-3">Add Update</InputHeading>
              <StyledEditHeaderCol className="ml-0 mr-2">
                <ModalTextArea
                  className="mr-4 mb-2"
                  placeholder="Add comment here"
                  wrap="hard"
                  inputmode="text"
                  enterkeyhint="next"
                  name="update"
                  spellcheck
                  value={comment}
                  onNeuChange={(
                    e: CustomEvent<NeuTextareaChangeEventDetail>
                  ) => {
                    const { value } = e.target as HTMLTextAreaElement;
                    setComment(value);
                  }}
                />
                <StyledRightAlignButton
                  className="mr-4"
                  color="primary"
                  onClick={() => handlePostComment()}
                  size="small"
                >
                  Add Update
                </StyledRightAlignButton>
              </StyledEditHeaderCol>
            </>
          )}
        </ModalCardContent>
      )}
      {(issueData || tempTaskObject) &&
        hasStoplightView &&
        view === 'search' && (
          // Issue info
          <ModalCardContent id="Issues-Modal-Stoplight-Card" className="pb-4">
            <SearchView
              attachedDisplay={
                attachedStoplight && Object.keys(attachedStoplight).length > 0
                  ? ([attachedStoplight] as (Issue & Stoplight)[])
                  : ([] as (Issue & Stoplight)[])
              }
              attachToItem={taskObject as TaskPost & Stoplight}
              attachToItemCompleted={taskObject.completed}
              prevAttached={attachedStoplight?.id ? [attachedStoplight.id] : []}
              type="stoplight"
              handleRemoveAttached={handleRemoveAttachStoplight}
              setItemsToAttach={setStoplightToAttach}
            />
          </ModalCardContent>
        )}
      {!loading && (
        <ModalFooter small right={!edit ? true : undefined}>
          {edit &&
            !taskObject.completed &&
            (authorizedUser.hcaid === taskObject.userId ||
              isEnterpriseAdmin) && <DeleteBtn task={taskObject} />}
          {!edit && (
            <NeuButton
              disabled={!showButton ? true : undefined}
              color="primary"
              onClick={() => {
                if (modalAction === 'UNIVERSAL-ADD') {
                  logTrackingEvent(logAdHocClick(modalType, 'save'));
                } else {
                  logTrackingEvent(logTaskClick('issues', 'save'));
                }
                if (haveControlNumber === true && taskObject.u_control_number) {
                  dispatch(verifyControlNumber(taskObject.u_control_number));
                } else {
                  handlePost();
                }
              }}
            >
              {loading || isLoadingcontrolNumber ? (
                <BtnSpinner
                  ariaLabel="spinner"
                  className="custom-save-spinner"
                  color="plain-0"
                  tiny
                />
              ) : (
                'Save Issue'
              )}
            </NeuButton>
          )}
          {edit && !taskObject.completed && (
            <NeuButton
              disabled={!showButton ? true : undefined}
              color="primary"
              onClick={showButton ? handlePost : undefined}
            >
              Save Issue
            </NeuButton>
          )}
          {edit &&
            taskObject.taskDate &&
            taskObject.completed &&
            taskObject.completedDate &&
            !taskObject.spotlight && (
              <StyledEditHeaderRow className="px-0" centered>
                <NeuContainer className="flex-row align-items-center px-1">
                  <StyledCircleDoneIcon color="plain-0">
                    done
                  </StyledCircleDoneIcon>
                  <NeuContainer>
                    <StyledEditHeaderRow className="pl-0">
                      <StyledBoldHeadingCompleted className="mt-0" autoWidth>
                        Completed
                      </StyledBoldHeadingCompleted>
                    </StyledEditHeaderRow>
                    <StyledEditHeaderRow className="pl-0">
                      <InputHeading
                        className="mt-0"
                        autoWidth
                        style={{ fontSize: '0.75rem' }}
                      >
                        {taskObject.completedDate &&
                          date.dayOfYr(taskObject.completedDate)}{' '}
                        @{' '}
                        {taskObject.completedDate &&
                          date.localTimeOfDay(taskObject.completedDate)}
                      </InputHeading>
                    </StyledEditHeaderRow>
                  </NeuContainer>
                </NeuContainer>
                <NeuContainer>
                  {isEnterpriseAdmin && <DeleteBtn task={taskObject} />}
                </NeuContainer>
              </StyledEditHeaderRow>
            )}
        </ModalFooter>
      )}
    </AddIssueCard>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  issueData: state.ModalReducer.modalData,
  modalType: state.ModalReducer.modalType,
  authorizedUser: state.AuthorizedUser.authorizedUser,
  categories: state.TaskReducer.categories,
  loading: state.TaskReducer.loading,
  sharedEmployees: state.EmployeeReducer.employees,
  searchedEmployeesToShare: state.EmployeeReducer.searchedEmpsToShare,
  searchedPatients: state.ReportReducer.searchedPatients,
  units: state.ConfigReducer.units,
  userSection: state.UserReducer.userSection,
  modalAction: state.ModalReducer.modalAction,
  isRoundStarted: state.RoundReducer.isRoundStarted,
  attachedStoplight: state.StoplightReducer.stoplightById,
  tempTaskObject: state.TaskReducer.tempTaskObject,
  tempModalState: state.TaskReducer.tempModalState,
  stoplightResponse: state.StoplightReducer.stoplightResponse,
  serviceCentralCategories: state.ConfigReducer.serviceCentralCategories,
  itAndSCategories: state.ConfigReducer.itAndSCategories,
  issueTypes: state.ConfigReducer.validTaskRoundingTypes,
  roundingTypes: state.ConfigReducer.validRoundingTypes,
  scLocations: state.TaskReducer.scLocations,
  geLocations: state.TaskReducer.geLocations,
  isLoadingSCLocations: state.TaskReducer.isLoadingSCLocations,
  controlNumberResponse: state.TaskReducer.controlNumberResponse,
  isLoadingcontrolNumber: state.TaskReducer.isLoadingcontrolNumber,
  associatedPatient: state.UserReducer.patientsToRound,
  associatedEmployee: state.UserReducer.selectedEmployee
});

export default connect(mapReduxStateToProps)(AddIssueModal);
