import styled from 'styled-components';

export const FinishRoundTypography = styled.p`
  line-height: 2rem;
  font-size: 1.4rem;
  max-width: 14em;
  margin-left: 1rem !important;
`;

export const StickyFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
  position: sticky;
  bottom: 0;
  height: 60px;
  border-top: 1px solid #b0b0b0;
  background: #f6f6f6;
  z-index: 12;
`;
