import { FC } from 'react';
import { useLocation } from 'react-router-dom';

// Custom Neutron components
import {
  StyledLegendDataText,
  StyledLegendLabel,
  StyledLegendList,
  StyledLegendListItem,
  StyledLegendListCont,
  StyledLegendSquare
} from './ChartLegend.styles';

// Type def for custom props
interface LegendProps {
  completeCount: number;
  completePercent: number;
  todoCount: number;
  todoPercent: number;
}

// Chart Legend for Census data
const ChartLegend: FC<LegendProps> = ({
  completeCount,
  completePercent,
  todoCount,
  todoPercent
}) => {
  const { pathname } = useLocation();

  return (
    <StyledLegendList id="Pie-Chart-Legend" lines="none">
      <StyledLegendListItem id="Completed-Pie-Chart-Item">
        <StyledLegendListCont>
          <StyledLegendSquare color="#00558C" />
          <StyledLegendLabel id="Completed-Pie-Chart-Label">
            Completed
            <StyledLegendDataText>
              {completePercent}% ({completeCount})
            </StyledLegendDataText>
          </StyledLegendLabel>
        </StyledLegendListCont>
      </StyledLegendListItem>
      <StyledLegendListItem id="ToDo-Pie-Chart-Item">
        <StyledLegendListCont>
          <StyledLegendSquare color="#E05929" />
          <StyledLegendLabel id="ToDo-Pie-Chart-Label">
            To Do
            <StyledLegendDataText>
              {todoPercent}% ({todoCount})
            </StyledLegendDataText>
          </StyledLegendLabel>
        </StyledLegendListCont>
      </StyledLegendListItem>
      {/* ******************************************* */}
      {/* ********* Removing till Phase II ********** */}
      {/* ******************************************* */}
      {/* {!pathname.includes('/employees') && (
        <StyledLegendListItem id="Unable-Pie-Chart-Item">
          <StyledLegendListCont>
            <StyledLegendSquare color="#279BBB" />
            <StyledLegendLabel id="Unable-Pie-Chart-Label">
              Unable to Round
              <StyledLegendDataText>
                {sumData(data) !== 0
                  ? Math.round((100 * data[2]) / sumData(data))
                  : 0}
                % ({data[2]})
              </StyledLegendDataText>
            </StyledLegendLabel>
          </StyledLegendListCont>
        </StyledLegendListItem>
      )} */}
    </StyledLegendList>
  );
};

export default ChartLegend;
