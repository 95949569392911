import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import CNEdListTable from '../../../components/customTables/cnedListTable';
import Loading from '../../../components/shared/Loading';
import SearchHeader from '../../../components/searchHeader';
import UnlistedEmployeeRoundModal from '../../../components/modals/unlistedEmployeeRoundModal';

import { RootState } from '../../../redux/store';
import { getCNEdSubjectsToRoundForList } from '../../../redux/actions/Employee.action';

import { ModalContainer } from '../../../services/ModalPortal/ModalPortal.Styles';
import { SupportContainer } from '../styles/Support.styles';
import SupportSideNav from '../../../components/sideNav/SupportSideNav';

import {
  AuthUserProps,
  Employee,
  RoundHistory
} from '../../../config/interfaces';

export interface CNEdListProps {
  authorizedUser?: AuthUserProps;
  cnedSubjectsToRound: (Employee & RoundHistory)[];
  isLoading: boolean;
  searchTerm: string;
  showModalFlag: boolean;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const CNEdList: FC<CNEdListProps> = ({
  authorizedUser,
  cnedSubjectsToRound = [],
  isLoading,
  searchTerm,
  showModalFlag,
  setSearchTerm
}) => {
  const dispatch = useDispatch();

  const { cnedFilter = 'all' } = useParams();

  useEffect(() => {
    if (authorizedUser?.hcaid)
      dispatch(
        getCNEdSubjectsToRoundForList({
          hcaid: authorizedUser.hcaid
        })
      );
  }, []);

  return (
    <SupportContainer id="Support-Container">
      <SupportSideNav type="cned" />
      <div style={{ width: '100%' }}>
        <SearchHeader searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        {isLoading ? (
          <Loading centered={false} />
        ) : (
          <CNEdListTable
            cnedFilter={cnedFilter}
            searchTerm={searchTerm}
            cnedRounds={cnedSubjectsToRound}
          />
        )}
      </div>

      {showModalFlag && (
        <ModalContainer>
          <UnlistedEmployeeRoundModal />
        </ModalContainer>
      )}
    </SupportContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    cnedSubjectsToRound: state.EmployeeReducer.cnedSubjectsToRoundForList,
    isLoading: state.EmployeeReducer.isLoadingSubjectsToRoundForList,
    showModalFlag: state.UserReducer.showUnlistedEmployeeModalFlag
  };
};

export default connect(mapReduxStateToProps)(CNEdList);
