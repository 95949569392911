/**
 * External Imports
 */
import { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NeuTab, NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';
import { useDispatch } from 'react-redux';

/**
 * Internal Imports
 */
import { clearSelectedEmployee } from '../../../redux/actions/User.action';
import {
  logCOGReportClick,
  logHRInsightsClick,
  logTableStart
} from '../../../utils/analyticsHelpers';
import { setLastPath } from '../../../utils/helpers';

/**
 * Styling Imports
 */
import {
  PopItem,
  PopIcon,
  IconPositioning,
  InlineRelativeContainer,
  ReportDropDownContainer,
  PopContent
} from '../../Header/mainNav/MainNav.styles';
import { useUserRolesRef } from '../../../services/UserRoles';

const ReportDropdown: FC<{}> = () => {
  const dispatch = useDispatch();
  const [reportDropdownState, setReportDropdownState] = useState(false);
  const [, setShowPopover] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logTrackingEvent } = useAnalyticsApi();

  const {
    isEnterpriseAdmin,
    isPatientRounder,
    isEmployeeRounder,
    isCSRNRounder,
    isCNEdRounder,
    isNonSupportRounder
  } = useUserRolesRef();

  return (
    <>
      <NeuTab
        id="Reports-Tab"
        onBlur={() => setReportDropdownState(false)}
        navy
        active={pathname.includes('reports')}
        onClick={() => setReportDropdownState(!reportDropdownState)}
      >
        <IconPositioning>
          Reports
          <NeuIcon small>arrow_drop_down</NeuIcon>
        </IconPositioning>
      </NeuTab>
      <InlineRelativeContainer onBlur={() => setShowPopover(false)}>
        <ReportDropDownContainer showReportDropdown={reportDropdownState}>
          {(isEnterpriseAdmin || isEmployeeRounder) && (
            <PopItem
              button
              onMouseDown={() => {
                window.open(
                  'https://hrinsights.medcity.net/analytics/emp-rounding',
                  '_blank'
                );
                logTrackingEvent(logHRInsightsClick());
              }}
            >
              <PopContent>
                HR Insights <PopIcon>launch</PopIcon>
              </PopContent>
            </PopItem>
          )}

          {(isEnterpriseAdmin || isCSRNRounder) && (
            <PopItem
              button
              onMouseDown={() =>
                window.open(
                  'https://app.powerbi.com/groups/me/apps/1384df98-12c7-4007-a470-a692392a8f60/reports/54e09b8c-a85a-4120-9fa4-c8f8df8daa1d?ctid=335a6d75-c3eb-4b1a-ac08-d49338816ca0&experience=power-bi',
                  '_blank'
                )
              }
            >
              <PopContent>
                CSC Reports <PopIcon>launch</PopIcon>
              </PopContent>
            </PopItem>
          )}
          {(isEnterpriseAdmin || isCNEdRounder) && (
            <PopItem
              button
              onMouseDown={() =>
                window.open(
                  'https://app.powerbi.com/groups/me/apps/1384df98-12c7-4007-a470-a692392a8f60/reports/d661531f-b8f0-4235-8247-5d23010e9dfd/4991f54aab60676900a1?ctid=335a6d75-c3eb-4b1a-ac08-d49338816ca0&experience=power-bi',
                  '_blank'
                )
              }
            >
              <PopContent>
                CNEd Reports <PopIcon>launch</PopIcon>
              </PopContent>
            </PopItem>
          )}

          {(isEnterpriseAdmin || isNonSupportRounder) && (
            <>
              {(isEnterpriseAdmin || isPatientRounder) && (
                <>
                  <PopItem
                    button
                    onMouseDown={() => {
                      window.open(
                        'https://www.powerbi.com/groups/me/apps/1384df98-12c7-4007-a470-a692392a8f60/reports/206e4c55-8696-467a-a80d-2d1a3d7ba420/ReportSection3c7d73a9f3f288b13269',
                        '_blank'
                      );
                      logTrackingEvent(logCOGReportClick());
                    }}
                  >
                    <PopContent>
                      COG Patient Reports <PopIcon>launch</PopIcon>
                    </PopContent>
                  </PopItem>
                  <PopItem
                    button
                    onMouseDown={() => {
                      setLastPath('/reports/patient');
                      navigate('/reports/patient');
                      logTrackingEvent(
                        logTableStart('/reports/patient/compliance')
                      );
                    }}
                  >
                    <PopContent>Patient Reports</PopContent>
                  </PopItem>
                </>
              )}
              {(isEnterpriseAdmin || isEmployeeRounder) && (
                <PopItem
                  button
                  onMouseDown={() => {
                    dispatch(clearSelectedEmployee());
                    setLastPath('/reports/employee');
                    navigate('/reports/employee');
                    logTrackingEvent(logTableStart('/reports/employee/rounds'));
                  }}
                >
                  <PopContent>Employee Reports</PopContent>
                </PopItem>
              )}
            </>
          )}
        </ReportDropDownContainer>
      </InlineRelativeContainer>
    </>
  );
};

export default ReportDropdown;
