/**
 * External Imports
 */
import { FC, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  NeuIcon,
  NeuButton,
  NeuCardTitle,
  NeuOption,
  NeuLabel
} from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import { Dropdown } from '../../shared/dropdowns';

import { RootState } from '../../../redux/store';
import {
  setSelectedPatient,
  updateUnlistedPatientRoundParams
} from '../../../redux/actions/User.action';
import { clearPatientRoundHistory } from '../../../redux/actions/Report.action';
import { clearTasksByAccountNumber } from '../../../redux/actions/Task.action';

import { handleUnlistedModal } from '../../../services/ModalPortal/utils';
import { logRoundProfile } from '../../../utils/analyticsHelpers';
/**
 * Global Type Definition Imports
 */
import { Patient, Unit } from '../../../config/interfaces';
/**
 * Style Imports
 */
import {
  CardHeader,
  ModalCardContent,
  InputHeading,
  ModalInput,
  UnlistedPatientRoundCard
} from './UnlistedPatientRoundModal.styles';

const { v1: uuidv1 } = require('uuid');

interface ModalProps {
  units: Unit[];
}

const UnlistedPatientRoundModal: FC<ModalProps> = ({ units }) => {
  const dispatch = useDispatch();
  const [paramsToPost, setParamsToPost] = useState<Patient>({} as Patient);
  const [open, setOpen] = useState(false);
  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  const { logTrackingEvent } = useAnalyticsApi();
  const navigate = useNavigate();

  const showButton = useMemo(
    () =>
      paramsToPost.room &&
      paramsToPost.bed &&
      paramsToPost.lastName &&
      paramsToPost.firstName &&
      paramsToPost.unitId,
    [paramsToPost]
  );

  const handleDropdownChange = (name: string, value: string) => {
    setParamsToPost({ ...paramsToPost, [name]: value });
  };

  const handleInputs = (event: CustomEvent<KeyboardEvent>, n?: string) => {
    const target = event.target as HTMLInputElement;
    let name = n;
    if (!n) {
      name = target.name;
    }
    const { value } = event.target as HTMLInputElement;
    if (paramsToPost) {
      switch (name) {
        case 'firstName':
        case 'lastName':
        case 'bed':
        case 'room':
          setParamsToPost({ ...paramsToPost, [name]: value });
          break;
        default: {
          console.log('error');
        }
      }
    }
  };

  const handlePost = () => {
    if (paramsToPost && showButton) {
      // TODO: Revisit unneeded params
      paramsToPost.mrn = `UL-${uuidv1()}`;
      // paramsToPost.chiefComplaint = 'NA';
      // paramsToPost.attendingPhysician = 'NA';
      // paramsToPost.lengthOfStay = 'NA';
      paramsToPost.sex = 'NA';
      // paramsToPost.chiefComplaint = 'NA';
      dispatch(updateUnlistedPatientRoundParams(paramsToPost));

      dispatch(clearPatientRoundHistory());
      dispatch(clearTasksByAccountNumber());
      dispatch(setSelectedPatient(null));
      logTrackingEvent(logRoundProfile());
      navigate('/patients/profile', {
        state: { isUnlistedPatient: true },
        replace: true
      });
    }
    handleUnlistedModal(modalContentRef);
  };

  return (
    <UnlistedPatientRoundCard
      className="show-modal"
      id="Unlisted-Patient-Modal"
      type="submit"
      maxWidth="413px"
      minHeight="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        <NeuCardTitle>
          <NeuIcon large color="primary-40" style={{ alignItems: 'center' }}>
            supervisor_account
          </NeuIcon>
          <NeuLabel style={{ color: '#093865', marginLeft: 10 }}>
            Unlisted Patient Round
          </NeuLabel>
        </NeuCardTitle>
        <NeuButton
          fill="flat"
          onClick={() => handleUnlistedModal(modalContentRef)}
        >
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      <ModalCardContent>
        <InputHeading>Unit</InputHeading>
        <Dropdown
          classes="pl-0"
          name="unitId"
          open={open}
          placeholder={
            units.find((unit: Unit) => unit.unitId === paramsToPost.unitId)
              ?.unit || 'Select Unit'
          }
          width="96%"
          setOpen={setOpen}
        >
          {units.map((unit: Unit) => (
            <NeuOption
              className={`dropdown-hover-item${
                paramsToPost?.unitId === unit.unitId ? ' selected' : ''
              }`}
              value={unit.unitId}
              key={unit.unitId}
              onClick={() => handleDropdownChange('unitId', unit.unitId)}
            >
              {unit.unit}
            </NeuOption>
          ))}
        </Dropdown>

        <InputHeading>First Name</InputHeading>
        <ModalInput
          id="Unlisted-Patient-First-Name-Input"
          type="text"
          name="firstName"
          autocomplete="off"
          placeholder="First Name"
          inputmode="text"
          enterkeyhint="next"
          autocorrect="off"
          required
          clearable
          onNeuInput={handleInputs}
        />
        <InputHeading>Last Name</InputHeading>
        <ModalInput
          id="Unlisted-Patient-Last-Name-Input"
          placeholder="Last Name"
          name="lastName"
          inputmode="text"
          enterkeyhint="next"
          required
          clearable
          onNeuInput={handleInputs}
        />
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'inline-block',
              flexDirection: 'column',
              width: '50%'
            }}
          >
            <InputHeading>Room No.</InputHeading>

            <ModalInput
              id="Unlisted-Patient-Room-Num-Input"
              type="text"
              name="room"
              autocomplete="off"
              placeholder="Room No"
              inputmode="text"
              enterkeyhint="next"
              autocorrect="off"
              onNeuInput={handleInputs}
            />
          </div>
          <div
            style={{
              position: 'relative',
              flexDirection: 'column',
              display: 'inline-block',
              width: '50%'
            }}
          >
            <InputHeading>Bed No.</InputHeading>
            <ModalInput
              id="Unlisted-Patient-Bed-Num-Input"
              type="text"
              name="bed"
              autocomplete="off"
              placeholder="Bed No"
              inputmode="text"
              enterkeyhint="next"
              autocorrect="off"
              onNeuInput={handleInputs}
            />
          </div>
        </div>
      </ModalCardContent>
      <NeuButton
        id="Unlisted-Patient-Begin-Round-Button"
        color="primary"
        style={{ marginRight: 20, marginBottom: 26, marginLeft: 263 }}
        onClick={showButton ? handlePost : undefined}
        disabled={!showButton ? true : undefined}
      >
        Begin Round
      </NeuButton>
    </UnlistedPatientRoundCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalState: state.ModalReducer.modal,
    units: state.ConfigReducer.units
  };
};

export default connect(mapReduxStateToProps)(UnlistedPatientRoundModal);
