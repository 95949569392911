import styled from 'styled-components';
import { NeuLabel, NeuInput, NeuItem } from '@neutron/react';

interface DropdownProps {
  showPopover: boolean;
}
export const UsersListContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 206px);
`;

export const QuestionBankContainer = styled.div`
  height: calc(100vh - 200px);
`;

export const EncountersContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 206px);
`;

export const EncountersSidePanelContainer = styled.div`
  width: 250px; !important;
  background-color: white;
  height: calc(100vh - 206px);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
  padding: 15px;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224);
    border-radius: 10px;
  }
`;

export const EncountersSidePanelSubheader = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #686868;
  margin-bottom: 10px;
`;

export const EncounterSidePanelInput = styled(NeuInput)`
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const EncounterSidePanelInputLabels = styled(NeuLabel)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;

export const EncounterHistorySidePanelLabels = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
`;

export const EncounterHistorySidePanelData = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 20px;
`;

export const DropDownSearchContainer = styled.div<DropdownProps>`
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  width: 175px;
  min-height: 27px;
  max-height: 250px;
  z-index: 10;
  position: absolute;
  background: white !important;
  opacity: 1;
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224);
    border-radius: 10px;
  }
  display: ${props => (props.showPopover ? 'block' : 'none')};
`;

export const DropItem = styled(NeuItem)`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-bottom: 0.5rem;
  opacity: 1;
  overflow: hidden;
  :hover {
    background-color: #f6f6f6;
  }
`;

export const EncountersTableContainer = styled.div`
  width: 100%;
`;

export const AreasContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 206px);
`;

export const TemplatesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AreasContainerInner = styled.div`
  width: 100%;
  background: white;
  height: calc(100vh - 206px);
`;
export const BackText = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  color: #2075ad;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export const JsonViewContainer = styled.div`
  width: 650px;
  height: calc(100vh - 330px);
  background: white;
  padding: 20px;
  margin-left: 20px;
  margin-top: 10px;
  box-shadow: 0px 2px 4px rgba(25, 25, 25, 0.2);
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: scroll;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224);
    border-radius: 10px;
  }
`;
