import ACTIONS from './actionType';

// Patient actions

export const postNote = data => {
  return { type: ACTIONS.PATIENT.ADD_NOTE, data };
};

export const removeNote = data => {
  return { type: ACTIONS.PATIENT.REMOVE_NOTE, data };
};

export const getPatientByAccountNumber = data => {
  return { type: ACTIONS.PATIENT.PATIENT_DETAILS, data };
};
