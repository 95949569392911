import styled from 'styled-components';

export const SupportContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 130px);
`;

export const TaskContainer = styled.div`
  width: 100%;
  height: calc(100vh - 206px);
`;
